import { useContext } from 'react';
import { Grid } from '@mui/material';
import { FtpAccounts } from 'component/partial/FtpAccounts';
import { SiteUserList } from 'component/partial/SiteUserList';
import { SiteDetail } from 'api/site';
import { SiteDetailsCard } from '../SitesDetailsCard';
import { DomainsListCard } from '../DomainsListCard';
import { SiteActivityCard } from '../SiteActivityCard';
import { ReadyToGoLiveCard } from '../ReadyToGoLiveCard';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';

interface Props {
  readonly siteDetails?: SiteDetail;
  readonly isWpSiteControl: boolean;
}

export function SiteDetailsOverview({ siteDetails, isWpSiteControl }: Props) {
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ReadyToGoLiveCard siteDetails={siteDetails} />
      </Grid>
      <Grid item xs={12}>
        <SiteActivityCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <SiteDetailsCard siteDetails={siteDetails} />
      </Grid>
      <Grid item xs={12} md={6}>
        <DomainsListCard domains={siteDetails?.domains} domain={siteDetails?.domain ?? ''} />
      </Grid>
      <Grid item xs={sdk || isWpSiteControl ? 12 : 6}>
        <FtpAccounts domain={siteDetails?.domain ?? ''} />
      </Grid>
      {!sdk && !isWpSiteControl && (
        <Grid item xs={6}>
          <SiteUserList siteDetails={siteDetails} />
        </Grid>
      )}
    </Grid>
  );
}
