import {
  Box,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChangeDomainDialog } from '../ChangeDomainDialog';
import { SiteDetailDomain, AdditionalDomains, AdditionalDomain } from 'api/site';
import { useDeleteDomain } from 'api/domain';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  readonly domain: string;
  readonly domains: (SiteDetailDomain | AdditionalDomains)[] | undefined;
}

export function DomainsListCard({ domain, domains }: Props) {
  const { t } = useTranslation();
  const [changeDomain, setChangeDomain] = useState<string | null>(null);
  const [domainToDelete, setDomainToDelete] = useState<AdditionalDomain | null>(null);
  const canChangeDomain = !['onrocket.site', 'wpdns.site'].some(url => domain.endsWith(url));
  const { siteId = '' } = useParams<{ siteId: string }>();
  const siteAdditionalDomains = domains?.find(
    domain => domain && 'additional_domains' in domain
  ) as AdditionalDomains | null;
  const deleteDomain = useDeleteDomain(siteId);

  return (
    <>
      <ConfirmationDialog
        action="delete"
        onClose={() => setDomainToDelete(null)}
        open={Boolean(domainToDelete)}
        onConfirm={async () => {
          await deleteDomain.mutateAsync({ id: domainToDelete?.id ?? 0 });
          setDomainToDelete(null);
        }}
        title={t('delete_domain')}
        description={t('delete_additional_domain', {
          title: domainToDelete?.domain,
        })}
      />
      {changeDomain ? (
        <ChangeDomainDialog siteId={siteId} domain={domain} onClose={() => setChangeDomain(null)} />
      ) : null}
      <Card>
        <CardHeader
          title={
            <Typography variant="h2">
              {t('domain_plural')} <Box component="span">| 1</Box>
            </Typography>
          }
        />
        <CardContent>
          <List>
            <ListItem>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <strong data-testid="domainListUrl">{domain}</strong>
                </Grid>
              </Grid>
              <Grid item xs={9} sm={3}>
                {t('primary')}
              </Grid>
              <Grid item xs={3} sm={1}>
                {canChangeDomain ? (
                  <Box display="inline-block" marginLeft={1}>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => setChangeDomain(domain)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                ) : null}
              </Grid>
            </ListItem>
            {siteAdditionalDomains?.additional_domains?.map(additionalDomain => {
              return (
                <ListItem key={additionalDomain.id}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={8}>
                      <strong data-testid="domainListUrl">{additionalDomain.domain}</strong>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} sm={3}>
                    {t('additional')}
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <Box display="inline-block" marginLeft={1}>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => setDomainToDelete(additionalDomain)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </>
  );
}
