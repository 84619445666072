import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LegendDot } from '../../LegendDot';
import { useGetWAFEventReports } from 'api/siteMetrics';
import { FC, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';

import { tooltip } from 'theme/custom/tooltip';

const PREFIX = 'EventsByType';

const classes = {
  bar: `${PREFIX}Bar`,
  barContainer: `${PREFIX}BarContainer`,
  tooltip: `${PREFIX}Tooltip`,
  typography: `${PREFIX}Typography`,
};

const StyledBox = styled(Box)({
  [`&.${classes.bar}`]: {
    marginBottom: '1.25rem',
    '& [class*="h1"]': {
      fontSize: '1.625rem',
    },
  },
  [`& .${classes.barContainer}`]: {
    height: '3.75rem',
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
  },
  [`& .${classes.tooltip}`]: {
    ...tooltip,
  },
  [`& .${classes.typography}`]: {
    borderBottom: '0.0625rem solid var(--color-hint-of-red)',
    marginBottom: '1.25rem',
    marginTop: '2.5rem',
    paddingBottom: '0.5rem',
  },
});

interface EventsByTypeProps {
  readonly siteId: string;
  readonly duration: string;
}
export type eventTypes = 'firewallrules' | 'waf' | 'bic' | 'ip';
export type eventLabels = {
  firewallrules: string;
  waf: string;
  bic: string;
  ip: string;
};

const colors = ['#57747f', '#60a9a2', '#93ba7a', '#d5a938', '#e89a51'];

const EventsByType: FC<EventsByTypeProps> = ({ siteId, duration }) => {
  const { data, refetch: getEventReport } = useGetWAFEventReports(siteId, duration);

  const { t } = useTranslation();

  useEffect(() => {
    getEventReport();
  }, [duration]);

  const knownTypes = ['bic', 'firewallCustom', 'firewallrules', 'ip', 'securitylevel', 'waf'];
  const knownLabels = {
    securitylevel: t('activity_securitylevel_label'), // need a label - Security Level
    firewallCustom: t('activity_firewallCustom_label'), // need a label - Custom Rules
    firewallrules: t('activity_firewallrules_label'),
    waf: t('activity_waf_label'),
    bic: t('activity_bic_label'),
    ip: t('activity_ip_label'),
  } as eventLabels;
  const eventsByType = data?.data?.data?.viewer?.zones[0]?.firewallEventsAdaptiveGroups ?? [];

  let total: number = 0;
  const splitEventsByType =
    eventsByType.reduce<{
      [key: string]: string | number;
    }>(
      (prev, datum) => {
        total += datum.count ?? 0;
        return {
          ...prev,
          [datum.dimensions.source]: datum.count ?? 0,
        };
      },
      { metrics: 'all' }
    ) ?? {};

  const calculatePercentages = (val: number) => {
    if (!val) return '0%';
    const decimal = (val * 100) / total;
    return parseFloat(decimal.toString()).toFixed(2) + '%';
  };

  return (
    <StyledBox className={classes.bar}>
      <Typography variant="h2" className={classes.typography}>
        {t('events_by_type')}
      </Typography>
      <Grid container spacing={2}>
        {knownTypes.map((type: string, index: number) => {
          return (
            <Grid key={index} item xs={6} md={3}>
              <Box textAlign="center">
                <LegendDot color={colors[index]} />
                {knownLabels[type as eventTypes]}
                <Typography variant="h1" component="div">
                  {splitEventsByType[type] ?? 0}
                </Typography>
                <Typography variant="body2">
                  {calculatePercentages((splitEventsByType[type] as number) ?? 0)}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box className={classes.barContainer}>
        <ResponsiveBar
          colors={colors}
          indexBy="metrics"
          layers={['bars', 'markers', 'legends']}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          layout="horizontal"
          valueScale={{ type: 'linear', max: total }}
          indexScale={{ type: 'band', round: true }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 0,
            tickRotation: 0,
            format: () => '',
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: () => '',
          }}
          labelTextColor="white"
          labelSkipWidth={12}
          labelSkipHeight={12}
          data={[splitEventsByType]}
          keys={knownTypes}
          tooltip={({ id, value }) => (
            <Box className={classes.tooltip}>
              <strong>{value}</strong> {knownLabels[id as eventTypes]}
            </Box>
          )}
        />
      </Box>
    </StyledBox>
  );
};

export default EventsByType;
