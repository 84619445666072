import { TableRow, TableCell, Grid, IconButton, Tooltip } from '@mui/material';
import { Done, DeleteOutline, VerticalAlignTopRounded } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'openapi-types';
import { DeleteThemeRequest, UpdateThemeRequest, ActivateThemeRequest } from 'api/themes';

export interface ShowModalData {
  data: DeleteThemeRequest | UpdateThemeRequest | ActivateThemeRequest;
  title: string;
}

interface InstalledThemeRowProps {
  readonly theme: components['schemas']['Theme'];
  readonly setConfirmModal: (data: ShowModalData) => void;
}

const InstalledThemeRow: FC<InstalledThemeRowProps> = ({ theme, setConfirmModal }) => {
  const { name, title, description, version, status, update } = theme;

  const { t } = useTranslation();
  const updateTooltip = t(update === 'none' ? 'update_not_available' : 'update');
  const statusTooltip = t(
    status === 'active' ? 'tooltip_toggle_deactivate' : 'tooltip_toggle_activate'
  );
  const deleteTooltip = t('tooltip_toggle_delete');

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{`${description?.substring(0, 50)}...`}</TableCell>
      <TableCell>{version}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <Tooltip arrow placement="top" title={statusTooltip}>
              <IconButton
                onClick={() =>
                  setConfirmModal({
                    data: {
                      status: 'activate',
                      theme: name,
                    },
                    title: title!,
                  })
                }
                color="primary"
                size="large"
                disabled={status === 'active'}
              >
                <Done />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow placement="top" title={updateTooltip}>
              <IconButton
                disabled={update === 'none'}
                onClick={() =>
                  setConfirmModal({
                    data: { update: true, theme: name },
                    title: title!,
                  })
                }
                color="primary"
                size="large"
              >
                <VerticalAlignTopRounded />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow placement="top" title={deleteTooltip}>
              <IconButton
                onClick={() =>
                  setConfirmModal({
                    data: { themes: name },
                    title: title!,
                  })
                }
                color="primary"
                size="large"
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
export default InstalledThemeRow;
