import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query';
import { ApiResponse, PaginatedApiResponse } from './types';
import { AxiosService } from 'api/axiosService';
export interface Plugin {
  name: string;
  status: string;
  title: string;
  update: string;
  version: string;
}

interface Payload {
  title: string;
}
export interface ActivatePayload extends Payload {
  plugin: string;
  status: 'activate' | 'deactivate';
}
export interface UpgradePayload extends Payload {
  plugin: string;
  update: boolean;
}

export interface InstallPlugin {
  plugins: string;
  activate: boolean;
}

export interface DeletePayload extends Payload {
  plugins: string;
}

interface UpdateResponse {
  name: string;
  note: string;
}

interface DeletePluginRequest {
  plugins: string;
}

interface UpgradePluginResponse extends UpdateResponse {
  old_version: string;
  new_version: string;
}

interface FetchPluginsRequest {
  siteId: string;
  page: number;
  perPage?: number;
  browse?: string;
  query?: string;
}

export interface NewPlugin {
  active_installs: number;
  description: string;
  downloaded: number;
  homepage: string;
  icons: {
    default?: string;
    '1x'?: string;
    '2x'?: string;
    svg?: string;
  };
  name: string;
  num_ratings: number;
  rating: number;
  slug: string;
  short_description: string;
  update: string;
  version: string;
}
export type PluginResult = Array<NewPlugin>;
export interface FetchPluginsResponse extends ApiResponse<PluginResult> {
  metadata: {
    page: number;
    page_size: number;
    total: number;
  };
}

export const usePluginList = (id: string) => {
  return useQuery({
    queryKey: ['installed-plugins'],
    queryFn: async () =>
      await AxiosService.get<PaginatedApiResponse<Plugin[]>>(`sites/${id}/plugins`),
    enabled: true,
  });
};

export const useDeletePlugin = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`delete-plugin-${id}`],

    mutationFn: async (data: DeletePluginRequest) =>
      await AxiosService.delete<ApiResponse<UpdateResponse>>(`sites/${id}/plugins`, {
        data,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
      queryClient.invalidateQueries({
        queryKey: ['plugins'],
      });
    },
  });
};

export const useChangeActiveStatus = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`change-plugin-${id}`],
    mutationFn: async (data: ActivatePayload) =>
      await AxiosService.patch(`sites/${id}/plugins`, data),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
    },
  });
};

export const useUpdatePlugin = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`update-plugin-${id}`],

    mutationFn: async (data: UpgradePayload) =>
      await AxiosService.put<ApiResponse<UpgradePluginResponse>>(`sites/${id}/plugins`, data),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
      queryClient.invalidateQueries({
        queryKey: ['plugins'],
      });
    },
  });
};

export const useGetPlugins = ({ siteId, page, perPage, browse, query }: FetchPluginsRequest) => {
  let queryParams = `page=${page}&per_page=${perPage}`;

  if (browse) {
    queryParams = `${queryParams}&browse=${browse}`;
  }
  if (query) {
    queryParams = `${queryParams}&query=${query}`;
  }

  return useQuery({
    queryKey: ['plugins', page, browse, query, perPage],

    queryFn: async () =>
      await AxiosService.get<FetchPluginsResponse>(`sites/${siteId}/plugins/search?${queryParams}`),

    enabled: false,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};

export const useInstallPlugin = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`install-plugin-${siteId}`],

    mutationFn: async (data: InstallPlugin) =>
      await AxiosService.post<ApiResponse<UpdateResponse>>(`sites/${siteId}/plugins`, data),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['plugins'],
      });
      queryClient.invalidateQueries({
        queryKey: ['installed-plugins'],
      });
    },
  });
};
