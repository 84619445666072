import { Typography, List } from '@mui/material';
import ContentViewCard from 'component/base/ContentViewCard';
import { SiteDetail, SiteSettings, useGetSiteSettings, useUpdateSettings } from 'api/site';
import { useParams } from 'react-router-dom';
import { SettingsCardSkeleton } from 'component/view/SiteDetails/Advanced/skeletons/SettingsCard';
import SettingRow, { RowOption } from './SettingsRow';

export interface ChangeDataSchema {
  key: string;
  value: string | number;
}

export const SettingsCard = ({ siteDetails }: { readonly siteDetails?: SiteDetail }) => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const staticSite: boolean = siteDetails?.site_type === 1 || false;
  const { data, isLoading } = useGetSiteSettings(siteId);

  const { mutateAsync } = useUpdateSettings(siteId);

  const fetchedRows = Object.entries(data?.data?.result ?? {}).map((s: any) => ({
    [s[0]]: s[1],
  }));
  const rows: { [key: string]: any }[] = [
    { database_management: !staticSite },
    { wp_cli: !staticSite },
    ...fetchedRows,
  ];

  const callUpdateApi = async (objectToUpdate: ChangeDataSchema) => {
    const updatedObject = { [objectToUpdate.key]: objectToUpdate.value };
    await mutateAsync(updatedObject as Partial<SiteSettings>);
  };

  return (
    <ContentViewCard title={<Typography variant="h2">Site Settings</Typography>}>
      <List>
        {!data || isLoading ? (
          <SettingsCardSkeleton />
        ) : (
          rows.map((s: RowOption, i: number) => {
            if (Object.keys(s).includes('wp_activity_log')) {
              return null;
            }

            if (
              staticSite &&
              (Object.keys(s).includes('database_management') ||
                Object.keys(s).includes('wp_cli') ||
                Object.keys(s).includes('wp_core_auto_updates') ||
                Object.keys(s).includes('wp_plugin_auto_updates') ||
                Object.keys(s).includes('wp_theme_auto_updates'))
            ) {
              return null;
            }

            return <SettingRow key={i} siteId={siteId} handleUpdate={callUpdateApi} data={s} />;
          })
        )}
      </List>
    </ContentViewCard>
  );
};

export default SettingsCard;
