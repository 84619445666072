import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { components } from 'openapi-types';

export const useGetInstalledThemes = (id: string) => {
  return useQuery({
    queryKey: ['installed-themes'],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['ListThemesResult']>(`sites/${id}/themes`),
  });
};

export type UpdateThemeRequest = components['schemas']['UpdateThemeRequest'];

export const useUpgradeInstalledTheme = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['installed-themes'],

    mutationFn: async (data: UpdateThemeRequest) =>
      await AxiosService.put<components['schemas']['UpdateThemeResponse']>(
        `sites/${id}/themes`,
        data
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['installed-themes'],
      });
    },
  });
};

export type ActivateThemeRequest = components['schemas']['ActivateThemeRequest'];

export const useChangeActiveStatus = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['installed-themes'],

    mutationFn: async (data: ActivateThemeRequest) =>
      await AxiosService.patch<components['schemas']['ActivateThemeResponse']>(
        `sites/${id}/themes`,
        data
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['installed-themes'],
      });
    },
  });
};

export type DeleteThemeRequest = components['schemas']['DeleteThemeRequest'];

export const useDeleteInstalledTheme = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['installed-themes'],

    mutationFn: async (data: DeleteThemeRequest) =>
      await AxiosService.delete<components['schemas']['DeleteThemeResponse']>(
        `sites/${id}/themes`,
        { data }
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['installed-themes'],
      });
    },
  });
};

export interface SearchThemesRequest {
  id: string;
  page: number;
  perPage: number;
  browse?: string;
  query: 'all' | 'blog' | 'business' | 'fashion' | 'food' | 'real+estate' | 'sports' | 'technology';
}

export type SearchThemesResultItem = NonNullable<
  components['schemas']['SearchThemesResponse']['result']
>[number];

export const useSearchThemes = ({ id, page, perPage, query, browse }: SearchThemesRequest) => {
  let queryParams: string = `page=${page}&per_page=${perPage}`;

  if (query === 'all' && !browse) {
    queryParams += '&browse=popular';
  } else {
    queryParams += `&query=${browse || query}`;
  }

  return useQuery({
    queryKey: ['themes', page, query, perPage],
    queryFn: async () =>
      await AxiosService.get<components['schemas']['SearchThemesResponse']>(
        `sites/${id}/themes/search?${queryParams}`
      ),
    enabled: false,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
};

export const useInstallTheme = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['installed-themes'],

    mutationFn: async (data: components['schemas']['InstallThemesRequest']) =>
      await AxiosService.post<components['schemas']['InstallThemesResponse']>(
        `sites/${id}/themes`,
        data
      ),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['themes'],
      });
      queryClient.invalidateQueries({
        queryKey: ['installed-themes'],
      });
    },
  });
};
