import { Box, Typography, Checkbox, InputAdornment, FormControlLabel } from '@mui/material';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useChangeSiteDomain } from 'api/site';
import { TextField } from 'component/base/TextField';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import isFQDN from 'validator/lib/isFQDN';

interface Props {
  readonly domain: string;
  readonly siteId: string;
  readonly onClose: () => unknown;
}

interface ChangeDomainData {
  domain: string;
  agreement: boolean;
}

export function ChangeDomainDialog({ domain, onClose, siteId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isPending } = useChangeSiteDomain(siteId);
  const methods = useForm<ChangeDomainData>({
    defaultValues: {
      domain: domain.replace('www.', ''),
      agreement: false,
    },
  });
  const { control, handleSubmit, reset, getValues, setValue } = methods;
  const { t } = useTranslation();

  const onSubmit = async (data: ChangeDomainData) => {
    if (!data.agreement) {
      enqueueSnackbar(t('change_domain_agreement_false_warning'), {
        key: 'mustAgreeToChangeDomain',
        variant: 'error',
      });
      return;
    }

    await mutateAsync({
      domain: data.domain,
    });

    onClose();
  };

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <ConfirmationDialog
      action="confirm"
      description={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box marginBottom={1}>
              <TextField
                defaultValue=""
                name="domain"
                disabled={isPending}
                placeholder={t('enter_your_domain_name_placeholder')}
                fullWidth
                rules={{
                  required: true,
                  validate: domain => {
                    return isFQDN(domain);
                  },
                }}
                formatValue={val => {
                  if (val.includes('https://www.')) {
                    return val.replace('https://www.', '');
                  } else if (val.includes('http://www.')) {
                    return val.replace('http://www.', '');
                  } else if (val.includes('https://')) {
                    return val.replace('https://', '');
                  } else if (val.includes('http://')) {
                    return val.replace('http://', '');
                  }
                  return val;
                }}
                startAdornment={
                  <InputAdornment
                    position="start"
                    disableTypography
                    component="button"
                    variant="outlined"
                  >
                    http(s)://
                  </InputAdornment>
                }
              />
            </Box>
            <Controller
              control={control}
              name="agreement"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  onChange={() => {
                    setValue('agreement', !getValues().agreement);
                  }}
                  checked={getValues().agreement}
                  disabled={isPending}
                  control={<Checkbox color="primary" className="checkbox-radio" />}
                  label={
                    <Typography>
                      {t('change_domain_agreement_checkbox_label', { domain })}
                    </Typography>
                  }
                  className="checkbox-form-control-label"
                />
              )}
            />
          </form>
        </FormProvider>
      }
      id="changeDomainModal"
      onConfirm={handleSubmit(onSubmit)}
      onClose={handleClose}
      open
      title={t('change_domain')}
      confirmText={t('change')}
    />
  );
}
