import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ActivityRowExpandedProps {
  readonly data: {
    clientRequestHTTPProtocol: string;
    clientRequestQuery: string;
    clientRequestPath: string;
    clientRequestHTTPHost: string;
    clientAsn: string;
    userAgent: string;
    rayName: string;
    clientRequestHTTPMethodName: string;
  };
}
interface Rows {
  label: string;
  value: string | number;
}

const ActivityRowExpanded: FC<ActivityRowExpandedProps> = ({
  data: {
    clientRequestHTTPProtocol,
    clientRequestQuery,
    clientRequestPath,
    clientRequestHTTPHost,
    clientAsn,
    userAgent,
    rayName,
    clientRequestHTTPMethodName,
  },
}) => {
  const { t } = useTranslation();

  const rows: Rows[] = [
    { label: t('activity_row_rayName'), value: rayName },
    { label: t('clientRequestHTTPMethodName'), value: clientRequestHTTPMethodName },
    { label: t('clientRequestHTTPProtocol'), value: clientRequestHTTPProtocol },
    { label: t('clientRequestHTTPHost'), value: clientRequestHTTPHost },
    { label: t('activity_row_clientRequestPath'), value: clientRequestPath },
    { label: t('clientRequestQuery'), value: clientRequestQuery },
    { label: t('activity_row_clientAsn'), value: clientAsn },
    { label: t('userAgent'), value: userAgent },
  ];

  return (
    <Grid container spacing={2}>
      {rows.map((r: Rows, i: number) => (
        <Grid key={i} item xs={12}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <strong>{t(r.label)}</strong>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography color={r.value === '' ? 'textSecondary' : 'inherit'}>
                {r.value === '' ? <i>{t('empty_string')}</i> : r.value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ActivityRowExpanded;
