import { useEffect } from 'react';
import { useSiteBandwidthUsage, useSiteBandwidthTopUsage } from 'api/siteMetrics';
import { Box, Typography } from '@mui/material';
import ReportingNoData from 'component/svg/chart/no-data.svg?react';
import { CircularProgress } from 'component/base/CircularProgress';
import { useTranslation } from 'react-i18next';
import { loadingContainer } from 'theme/custom/loading-container';
import { noData } from 'theme/custom/no-data';
import TopUsage from './TopUsage';
import { ResponsiveLine } from '@nivo/line';
import { getTickFormatForDuration } from 'utils/reporting';
import { tooltip } from 'theme/custom/tooltip';
import { formatDataSize } from 'utils/number';

interface Props {
  readonly siteId: string;
  readonly duration: string;
}

export default function Overview({ siteId, duration }: Props) {
  const siteBandwidthUsage = useSiteBandwidthUsage({
    id: siteId,
    duration,
  });
  const siteBandwidthTopUsage = useSiteBandwidthTopUsage({
    id: siteId,
    duration,
  });

  const { isFetched: isUsageFetched } = siteBandwidthUsage;
  const { isFetched: isTopsUsageFetched } = siteBandwidthTopUsage;

  const { t } = useTranslation();

  // if first request has no data assuming the rest of the reports have no data

  useEffect(() => {
    if (isUsageFetched) {
      siteBandwidthUsage.refetch();
    }
    if (isTopsUsageFetched) {
      siteBandwidthTopUsage.refetch();
    }
  }, [duration, isUsageFetched, isTopsUsageFetched]);

  const loadingContainerClasses = {
    ...loadingContainer,
    display: 'flex',
    height: '21.875rem',
    '&:not(:first-of-type)': {
      marginTop: '0 !important',
    },
  };

  if (siteBandwidthUsage.isLoading || siteBandwidthTopUsage.isLoading) {
    return (
      <Box sx={loadingContainerClasses}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  const lineData =
    siteBandwidthUsage?.data?.data?.data?.viewer.zones[0].series
      .map(datum => {
        return {
          y: datum.sum.edgeResponseBytes,
          x: datum.dimensions.ts,
        };
      })
      .sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime()) ?? [];

  // testing only one api, expecting data from both api's
  if (
    !siteBandwidthTopUsage.data?.data?.data?.viewer.zones[0].countries.length &&
    !siteBandwidthTopUsage.isLoading &&
    !siteBandwidthUsage.isLoading
  ) {
    return (
      <Box sx={loadingContainerClasses}>
        <Box sx={noData}>
          <ReportingNoData />
          <Typography variant="h3">{t('no_data_to_display')}</Typography>
          <Typography>{t('no_data_to_display_description')}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {lineData.length ? (
        <Box
          sx={{
            ...loadingContainer,
            display: 'flex',
            height: '21.875rem',
          }}
        >
          <ResponsiveLine
            data={[
              {
                id: 'Data Transfer',
                data: lineData,
              },
            ]}
            colors={['#095bb4']}
            animate={false}
            margin={{ top: 30, right: 30, bottom: 30, left: 60 }}
            xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: true }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            yFormat={val => formatDataSize(Number(val) / 1024)}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              format: getTickFormatForDuration(duration),
              tickSize: 0,
              tickPadding: 5,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              format: val => formatDataSize(val / 1024),
            }}
            enableGridX={false}
            curve={'linear'}
            enablePoints={false}
            useMesh
            enableSlices="x"
            xFormat="time:%Y-%m-%d"
            sliceTooltip={({ slice }) => {
              return (
                <Box sx={tooltip}>
                  {slice.points.map(point => (
                    <Box key={point.id}>
                      <Typography variant="caption">{point.data.xFormatted}</Typography>
                      <Box>
                        <strong>{point.data.yFormatted}</strong> {point.serieId}
                      </Box>
                    </Box>
                  ))}
                </Box>
              );
            }}
            {...(lineData.length === 1
              ? {
                  enablePoints: true,
                  pointSize: 10,
                  enableGridX: true,
                }
              : null)}
          />
        </Box>
      ) : null}
      <TopUsage data={siteBandwidthTopUsage.data} />
    </>
  );
}
