import { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, ListItem, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import PHPVersion from 'component/svg/settings/php-version.svg?react';
import DBManagement from 'component/svg/settings/phpmyadmin.svg?react';
import PluginUpdate from 'component/svg/settings/plugin-update.svg?react';
import SSHAccess from 'component/svg/settings/ssh-access.svg?react';
import WPCache from 'component/svg/settings/wpcore-update.svg?react';
import Themes from 'component/svg/settings/theme-update.svg?react';
import WPCliIcon from 'component/svg/settings/wp-cli.svg?react';
import ToggleSettings from './ToggleSetting';
import ChangeSelect from './ChangeSelect';
import { ChangeDataSchema } from './SettingsCard';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { isInIframe } from 'utils/iframe';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { WPCli } from 'component/partial/WPCli';

import { settingsItem, settingsGrid, settingsIcon } from 'theme/custom/settings';
import { useTranslation } from 'react-i18next';

const PREFIX = 'SettingsRow';

const classes = {
  settingsItem: `${PREFIX}SettingsItem`,
  settingsGrid: `${PREFIX}SettingsGrid`,
  settingsIcon: `${PREFIX}SettingsIcon`,
};

const StyledListItem = styled(ListItem)({
  [`&.${classes.settingsItem}`]: {
    ...settingsItem,
  },
  [`& .${classes.settingsGrid}`]: {
    ...settingsGrid,
  },
  [`& .${classes.settingsIcon}`]: {
    ...settingsIcon,
  },
});

export interface RowOption {
  [key: string]: string | number | boolean;
}

export interface SettingsRowProps {
  readonly data: RowOption;
  readonly handleUpdate: (data: { key: string; value: number | string }) => Promise<unknown>;
  readonly siteId: string;
}

const SettingsRow = ({ data, handleUpdate, siteId }: SettingsRowProps) => {
  const [[name, value]] = Object.entries(data);

  const { t } = useTranslation();
  const { sendMessage } = useContext(PostMessageContext);

  const handleChange = (newValue: ChangeDataSchema) => {
    return handleUpdate({
      key: newValue.key === 'current_php_version' ? 'new_php_version' : newValue.key,
      value: newValue.value,
    });
  };

  const versions = ['5.6', '7.0', '7.2', '7.3', '7.4', '8.0', '8.1', '8.2', '8.3'];

  const meta = () => {
    switch (name) {
      case 'current_php_version':
        return {
          action: (
            <ChangeSelect
              name={name}
              onChange={handleChange}
              options={versions.map(version => ({ name: version, value: version }))}
              currentValue={typeof value === 'string' && value.includes('native') ? '7.4' : value}
            />
          ),
          icon: <PHPVersion />,
        };
      case 'wp_core_auto_updates':
        return {
          action: (
            <ChangeSelect
              name={name}
              onChange={handleChange}
              options={[
                {
                  value: 0,
                  name: t('off'),
                },
                {
                  value: 1,
                  name: t('latest_version'),
                },
                {
                  value: 2,
                  name: t('minor_versions'),
                },
              ]}
              currentValue={Number(value)}
            />
          ),
          icon: <WPCache />,
        };
      case 'wp_theme_auto_updates':
        return {
          action: (
            <ToggleSettings
              onChange={handleChange}
              name="wp_theme_auto_updates"
              value={value === 1 ? true : false}
            />
          ),
          icon: <Themes />,
        };
      case 'wp_plugin_auto_updates':
        return {
          action: (
            <ToggleSettings
              onChange={handleChange}
              name="wp_plugin_auto_updates"
              value={value === 1 ? true : false}
            />
          ),
          icon: <PluginUpdate />,
        };
      case 'ssh_access':
        return {
          action: (
            <ToggleSettings
              onChange={handleChange}
              name="ssh_access"
              value={value === 1 ? true : false}
            />
          ),
          icon: <SSHAccess />,
        };
      case 'wp_cli':
        return {
          action: <WPCli siteId={siteId} />,
          icon: <WPCliIcon />,
        };
      default:
        return {
          action: (
            <ProgressiveButton
              variant="outlined"
              text="phpMyAdmin"
              isLoading={false}
              endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
              onClick={() => {
                if (isInIframe()) {
                  sendMessage(
                    JSON.stringify({
                      type: 'externalLink',
                      data: {
                        relative: true,
                        link: `/manage/${siteId}/phpmyadmin?token=${window.sessionStorage.getItem(
                          'user_session'
                        )}`,
                      },
                    })
                  );
                } else {
                  window.open(`/manage/${siteId}/phpmyadmin`);
                }
              }}
            />
          ),
          icon: <DBManagement />,
        };
    }
  };

  return (
    <StyledListItem className={classes.settingsItem}>
      <Box className={classes.settingsIcon}>{meta().icon}</Box>
      <Grid container spacing={2} className={classes.settingsGrid}>
        <Grid item xs={12} md={8}>
          <Typography variant="h3">{t(`${name}_title`)}</Typography>
          <Typography>{t(`${name}_description`)}</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {meta().action}
        </Grid>
      </Grid>
    </StyledListItem>
  );
};

export default SettingsRow;
