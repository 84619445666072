import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  Box,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useCreateStaging,
  SiteDetail,
  useDeleteStaging,
  usePublishStaging,
  useGetTaskStatus,
  useSiteDetails,
} from 'api/site';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { cssVar } from 'utils/css';
import { delay } from 'utils/delay';

const PREFIX = 'CurrentMode';

const classes = {
  card: `${PREFIX}Card`,
  cardContent: `${PREFIX}CardContent`,
  grid: `${PREFIX}Grid`,
  actions: `${PREFIX}Actions`,
  textField: `${PREFIX}TextField`,
};

const StyledCard = styled(Card)({
  [`&.${classes.card}`]: {
    backgroundColor: cssVar('--color-ebb'),
    boxShadow: 'none',
    textAlign: 'center',
    '@media (min-width: 37.5rem)': {
      textAlign: 'left',
    },
  },
  [`& .${classes.cardContent}`]: {
    padding: '0.625rem 1rem 0.625rem 0.625rem',
    '&:last-child': {
      paddingBottom: '0.625rem',
    },
  },
  [`& .${classes.grid}`]: {
    '@media (min-width: 75rem)': {
      flexWrap: 'nowrap',
    },
    '& > *:first-of-type': {
      flex: '0 0 100%',
      '@media (min-width: 75rem)': {
        flex: '0 0 auto',
      },
    },
    '& > *:last-of-type': {
      flex: '0 0 100%',
      '@media (min-width: 75rem)': {
        flex: '1 1 auto',
      },
      '& > * > *:last-of-type': {
        '@media (min-width: 75rem)': {
          textAlign: 'right',
        },
      },
    },
  },
  [`& .${classes.textField}`]: {
    width: '100%',
    '@media (min-width: 37.5rem)': {
      flexShrink: 0,
      width: 'auto',
    },
    '&:not(:last-child)': {
      marginBottom: '1rem',
      '@media (min-width: 37.5rem)': {
        margin: '0 1rem 0 0',
      },
    },
    '& > *': {
      backgroundColor: cssVar('--color-white'),
    },
  },
  [`& .${classes.actions}`]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '@media (min-width: 37.5rem)': {
      justifyContent: 'flex-start',
    },
    '@media (min-width: 75rem)': {
      justifyContent: 'flex-end',
    },
    '& p': {
      margin: '0 0.625rem',
    },
  },
});

type CurrentTask = 'creatingStaging' | 'deletingStaging' | 'publishingStaging';

interface Props {
  readonly siteDetails?: SiteDetail;
}

interface ChildProps {
  readonly siteDetails: SiteDetail;
  readonly siteId: number;
  readonly currentTask: CurrentTask | null;
}

function useGetStatusPollingAfterCreate(siteId: number) {
  const getTaskStatus = useGetTaskStatus(siteId.toString());

  return async (taskId: string | undefined) => {
    if (!taskId) {
      throw new Error('Task ID is missing');
    }
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const result = await getTaskStatus.mutateAsync(taskId);

      const taskStatus = result.data.result?.[0]?.task_status;

      if (taskStatus === 'DONE') {
        return;
      } else if (taskStatus === 'ERROR') {
        throw new Error('Task failed');
      }

      await delay(10_000);
    }
  };
}

function getMode(siteDetails: SiteDetail, currentSiteId: number) {
  if (typeof siteDetails.production === 'number') {
    if (siteDetails.production === currentSiteId) {
      return 'production';
    }
    return 'staging';
  }

  if (
    typeof siteDetails.staging?.staging_id === 'undefined' ||
    (typeof siteDetails.staging?.staging_id === 'number' &&
      siteDetails.staging.staging_id !== currentSiteId)
  ) {
    return 'production';
  }

  return 'staging';
}

const stagingIconsByValue: { [key: string]: React.ReactNode } = {
  staging: <SettingsIcon />,
  production: <PlayArrowOutlinedIcon />,
};

function CurrentModeDropdown({ currentTask, siteDetails, siteId }: ChildProps) {
  const navigate = useNavigate();
  const value = getMode(siteDetails, siteId);
  const stagingId = siteDetails?.staging?.staging_id ?? null;
  const { t } = useTranslation();
  const staticSite: boolean = siteDetails?.site_type === 1 || false;

  const dropdownItems = [{ value: 'production', label: t('production') }];

  if (!staticSite) {
    dropdownItems.push({
      value: 'staging',
      label: t('staging'),
    });
  }

  return (
    <TextField
      data-testid="select-current-mode"
      select
      value={value}
      variant="outlined"
      onChange={() => {
        if (value === 'staging') {
          navigate(`/sites/${siteDetails?.production ?? ''}`);
        } else {
          navigate(`/sites/${siteDetails?.staging?.staging_id ?? ''}`);
        }
      }}
      InputProps={{
        disabled: Boolean(currentTask),
      }}
      className={classes.textField}
    >
      {dropdownItems.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.value === 'staging' && stagingId === null}
          data-testid={`current-mode-${option.value}`}
        >
          <Box
            component="span"
            marginRight={1}
            sx={{ display: 'block', verticalAlign: 'middle', '& > svg': { display: 'block' } }}
          >
            {stagingIconsByValue[option.value]}
          </Box>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function CurrentModeDescription({ currentTask, siteDetails, siteId }: ChildProps) {
  const mode = getMode(siteDetails, siteId);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();

  if (currentTask === 'creatingStaging') {
    return <Typography>{t('create_staging_message')}</Typography>;
  } else if (currentTask === 'deletingStaging') {
    return <Typography>{t('delete_staging_message')}</Typography>;
  } else if (currentTask === 'publishingStaging') {
    return <Typography>{t('publish_staging_message')}</Typography>;
  } else if (mode === 'production') {
    return (
      <Typography>
        {t('staging_warning_message')}
        <IconButton
          aria-describedby="productionVsStaging"
          onClick={handleClick}
          color="primary"
          size="large"
        >
          <HelpOutlineIcon />
        </IconButton>
        <Popover
          id="productionVsStaging"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography variant="h3">{t('staging_modes_title')}</Typography>
          <Typography>{t('staging_modes_description')}</Typography>
        </Popover>
      </Typography>
    );
  } else if (mode === 'staging') {
    return (
      <Typography>
        {t('staging_publish_instructions')}
        <IconButton
          aria-describedby="publishToProduction"
          onClick={handleClick}
          color="primary"
          size="large"
        >
          <HelpOutlineIcon />
        </IconButton>
        <Popover
          id="publishToProduction"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography variant="h3">{t('staging_publish_title')}</Typography>
          <Typography>{t('staging_publish_description')}</Typography>
        </Popover>
      </Typography>
    );
  }

  return null;
}

function CurrentModeActions({
  currentTask,
  siteDetails,
  siteId,
  setCurrentTask,
}: ChildProps & {
  readonly setCurrentTask: (task: CurrentTask | null) => unknown;
}) {
  const productionId = siteDetails?.production?.toString() ?? '';
  const deleteStaging = useDeleteStaging(productionId);
  const createStaging = useCreateStaging(siteId.toString());
  const publishStaging = usePublishStaging(productionId);
  const [showConfirmation, setShowConfirmation] = useState<'delete' | 'publish' | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch: siteDetailsRefetch } = useSiteDetails(
    {
      id: siteId.toString(),
    },
    { enabled: false }
  );
  const getStagingStatusPolling = useGetStatusPollingAfterCreate(siteId);
  const getProductionStatusPolling = useGetStatusPollingAfterCreate(Number(productionId));
  const value = getMode(siteDetails, siteId);
  const stagingId = siteDetails?.staging?.staging_id ?? null;
  const staticSite: boolean = siteDetails?.site_type === 1 || false;

  if (value === 'production') {
    if (!stagingId && !staticSite) {
      return (
        <ProgressiveButton
          sx={{ whiteSpace: 'nowrap', minWidth: '155px' }}
          onClick={async () => {
            try {
              setCurrentTask('creatingStaging');
              const result = await createStaging.mutateAsync();
              await getStagingStatusPolling(result.data.result?.task_id);
              const siteDetailsResult = await siteDetailsRefetch();
              enqueueSnackbar(t('staging_create'), {
                key: 'stagingCreate',
                variant: 'success',
              });
              navigate(`/sites/${siteDetailsResult.data?.data.result?.staging?.staging_id}`);
            } finally {
              setCurrentTask(null);
            }
          }}
          isLoading={currentTask === 'creatingStaging'}
          disabled={currentTask === 'creatingStaging'}
          text="Create Staging"
        />
      );
    }

    return null;
  } else if (value === 'staging') {
    return (
      <>
        {showConfirmation === 'delete' && (
          <ConfirmationDialog
            action="delete"
            title={t('delete_staging')}
            description={t('delete_staging_confirmation_description')}
            onClose={() => setShowConfirmation(null)}
            onConfirm={async () => {
              try {
                setCurrentTask('deletingStaging');
                await deleteStaging.mutateAsync();
                enqueueSnackbar(t('staging_delete'), {
                  key: 'stagingDelete',
                  variant: 'success',
                });
                navigate(`/sites/${siteDetails.production}`);
              } finally {
                setCurrentTask(null);
              }
            }}
          />
        )}
        {showConfirmation === 'publish' && (
          <ConfirmationDialog
            action="confirm"
            title={t('publish_staging_confirmation_title')}
            description={t('publish_staging_confirmation_description')}
            onClose={() => setShowConfirmation(null)}
            onConfirm={async () => {
              try {
                setCurrentTask('publishingStaging');
                const result = await publishStaging.mutateAsync();
                await getProductionStatusPolling(result.data.result?.task_id);
                const siteDetailsResult = await siteDetailsRefetch();
                enqueueSnackbar(t('staging_publish'), {
                  key: 'stagingPublish',
                  variant: 'success',
                });
                navigate(`/sites/${siteDetailsResult.data?.data.result?.production}`);
              } finally {
                setCurrentTask(null);
              }
            }}
          />
        )}

        <Stack spacing={2} direction="row" alignItems="center">
          <Button
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => {
              setShowConfirmation('delete');
            }}
            disabled={currentTask === 'deletingStaging' || currentTask === 'publishingStaging'}
            color="primary"
          >
            {t('delete_staging')}
          </Button>
          <Typography>or</Typography>
          <Button
            onClick={() => {
              setShowConfirmation('publish');
            }}
            variant="contained"
            color="primary"
            disabled={currentTask === 'deletingStaging' || currentTask === 'publishingStaging'}
          >
            {t('publish')}
          </Button>
        </Stack>
      </>
    );
  }

  return null;
}

export function CurrentMode({ siteDetails }: Props) {
  const params = useParams<{ siteId: string }>();
  const [currentTask, setCurrentTask] = useState<CurrentTask | null>(null);
  const siteId = Number(params.siteId);

  if (!siteDetails) {
    return null;
  }

  return (
    <StyledCard className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Stack
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          direction={{ xs: 'column', sm: 'row' }}
        >
          <Stack spacing="1" alignItems="center" direction={{ xs: 'column', sm: 'row' }}>
            <CurrentModeDropdown
              currentTask={currentTask}
              siteDetails={siteDetails}
              siteId={siteId}
            />
            <CurrentModeDescription
              currentTask={currentTask}
              siteDetails={siteDetails}
              siteId={siteId}
            />
          </Stack>
          <CurrentModeActions
            currentTask={currentTask}
            setCurrentTask={setCurrentTask}
            siteDetails={siteDetails}
            siteId={siteId}
          />
        </Stack>
      </CardContent>
    </StyledCard>
  );
}
