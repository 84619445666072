import { useState } from 'react';
import ContentViewCard from 'component/base/ContentViewCard';

import paginate from 'utils/paginate';
import PaginationControls from 'component/base/PaginationControls';
import {
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { CreateBackupRequest, useCreateBackup, useDeleteBackup, useGetBackups } from 'api/backups';
import { useParams } from 'react-router';
import { sortByKey } from 'utils/sort';
import LoadingSkeleton from './components/LoadingSkeleton';
import { SearchRounded } from '@mui/icons-material';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useSnackbar } from 'component/hooks/useSnackbar';
import CreateBackup from './components/CreateBackup';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import BackupList from './components/BackupList';
import { AutomatedBackups } from './components/AutomatedBackups';
import { loadDefaultPerPage } from 'utils/paginate';
import { SiteDetail } from '../../../../api/site';

interface PaginationState {
  perPage: number;
  activePageNumber: number;
  filter: string;
}

interface RestoreModal extends ConfirmationModal {
  onSubmit: () => Promise<unknown>;
}

export interface ConfirmationModal {
  open: boolean;
  backupId: null | number;
  type: 'delete' | 'restore';
}

const initConfirmation: ConfirmationModal = {
  open: false,
  backupId: null,
  type: 'delete',
};

const BackupPage = ({ siteDetails }: { readonly siteDetails?: SiteDetail }) => {
  const { t } = useTranslation();
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState<PaginationState>({
    perPage: loadDefaultPerPage(),
    activePageNumber: 1,
    filter: '',
  });

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal | RestoreModal>(
    initConfirmation
  );
  const { mutateAsync: deleteBackup, isPending: deleteLoading } = useDeleteBackup(siteId);
  const handleDeleteBackup = async () => {
    if (confirmationModal.backupId) {
      let snackMessage: string = t('backup_deleted_successfully');
      let snackType: 'success' | 'error' = 'success';
      try {
        await deleteBackup(confirmationModal.backupId);
        setConfirmationModal(initConfirmation);
      } catch (err) {
        snackMessage = t('backup_action_failed', { type: t('backup_label_delete').toLowerCase() });
        snackType = 'error';
      } finally {
        enqueueSnackbar(snackMessage, {
          key: 'errorCreate',
          variant: snackType,
        });
      }
    }
  };

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { mutateAsync, isPending: createLoading } = useCreateBackup(siteId);
  const { data, isLoading, refetch } = useGetBackups(siteId);
  const raw = data?.data?.result || [];
  const filteredBackups =
    pagination.filter.length > 0
      ? raw.filter(b => b.label?.toLowerCase().indexOf(pagination.filter.toLowerCase()) !== -1)
      : raw;
  const backupList = paginate(filteredBackups, pagination.perPage, pagination.activePageNumber);
  const orderedBackups = sortByKey(backupList, 'created', true);
  const staticSite: boolean = siteDetails?.site_type === 1 || false;

  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (perPage: number) => {
    setPagination({ ...pagination, activePageNumber: 1, perPage });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ ...pagination, activePageNumber: 1, filter: event.target.value });
  };

  const submit = async (postData: CreateBackupRequest) => {
    await mutateAsync(postData);
    setConfirmModal(false);
  };

  const closeModal = () => setConfirmModal(false);

  const confirmRestore = async () => {
    try {
      await (confirmationModal as RestoreModal).onSubmit();
      setConfirmationModal(initConfirmation);
    } catch {
      // error
    }
  };

  const renderConfirmationDescription = () => {
    if (['delete', 'restore'].includes(confirmationModal.type)) {
      return t('backup_short_confirmation_description', { type: confirmationModal.type });
    }
    return '';
  };

  return (
    <>
      {!staticSite ? (
        <ContentViewCard title={<Typography variant="h2">{t('backup_card_title')}</Typography>}>
          <>
            <Grid spacing={1} container direction="row" justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <TextField
                  onChange={handleFilter}
                  variant="outlined"
                  placeholder={t('backup_search_label')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disableTypography component="button">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <Box>
                  <Button onClick={() => setConfirmModal(true)} variant="contained" color="primary">
                    {t('backup_create_header')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <TableContainer>
              <Table aria-label={'backups table'}>
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">{t('label')}</TableCell>
                    <TableCell>{t('backup_table_status')}</TableCell>
                    <TableCell>{t('created')}</TableCell>
                    <TableCell>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {isLoading ? (
                    <LoadingSkeleton />
                  ) : pagination.filter !== '' && filteredBackups.length === 0 ? (
                    <NoResultsFound colSpan={4} />
                  ) : (
                    <BackupList
                      setConfirmationModal={setConfirmationModal}
                      list={orderedBackups}
                      refetch={refetch}
                      modalData={confirmationModal}
                      siteId={siteId}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {!isLoading && (
              <PaginationControls
                totalRowCount={filteredBackups.length}
                perPage={pagination.perPage}
                onPageChange={handlePaginationChange}
                onPerPageChange={handlePerPage}
              />
            )}
          </>
        </ContentViewCard>
      ) : null}
      <AutomatedBackups siteId={siteId} />

      <CreateBackup
        isLoading={createLoading}
        onSubmit={submit}
        open={confirmModal}
        onClose={closeModal}
      />

      <ConfirmationDialog
        action={confirmationModal.type === 'restore' ? 'confirm' : 'delete'}
        forceLoadingState={Boolean(deleteLoading)}
        onClose={() => setConfirmationModal(initConfirmation)}
        open={confirmationModal.open}
        onConfirm={confirmationModal.type === 'restore' ? confirmRestore : handleDeleteBackup}
        title={t('backup_tooltip', {
          type:
            confirmationModal.type === 'delete'
              ? t('backup_label_delete')
              : t('backup_label_restore'),
        })}
        description={renderConfirmationDescription()}
      />
    </>
  );
};

export default BackupPage;
