import {
  Box,
  Card,
  TextField,
  CardContent,
  CardHeader,
  InputAdornment,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Select,
  SelectChangeEvent,
  MenuItem,
  TableBody,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Pagination } from '@mui/material';
import { Search, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { EventTimeReport, useGetEventTimeReport, ReportTypes } from 'api/siteMetrics';
import React, { FC, useEffect, useState } from 'react';
import ActivityTableRowSkeleton from './skeletons/ActivityTableRowSkeleton';
import ActivityTableRow from './components/ActivityTableRow';
import { sortByKey } from 'utils/sort';
import { useTranslation } from 'react-i18next';

import { pagination } from 'theme/custom/pagination';

const PREFIX = 'ActivityLog';

const classes = {
  pagination: `${PREFIX}Pagination`,
  search: `${PREFIX}Search`,
};

const StyledCard = styled(Card)({
  [`& .${classes.pagination}`]: {
    ...pagination,
  },
  [`& .${classes.search}`]: {
    '@media (min-width: 37.5rem)': {
      maxWidth: '18.75rem',
    },
  },
});

export interface ActivityLogProps {
  readonly siteId: string;
  readonly duration: string;
}

export interface InvertState {
  key: string | null;
  invert: boolean;
}

interface TableHeader {
  key: string;
  label: string;
  hideAction?: boolean;
}

const ActivityLog: FC<ActivityLogProps> = ({ siteId, duration }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(10);
  const [invertList, setInvertList] = useState<InvertState>({
    invert: false,
    key: null,
  });

  const {
    data: fetchedReport,
    refetch: getEventTimeReport,
    isFetching,
  } = useGetEventTimeReport(siteId, duration);

  useEffect(() => {
    getEventTimeReport();
  }, [duration, siteId]);

  const data = fetchedReport?.data?.data?.viewer?.zones[0] ?? {
    activity: undefined,
    total: undefined,
  };
  const activity = data?.activity ?? [];

  const fuzzySearch = (query: string) => {
    const search = query.split(' ');
    const ret = activity.reduce((found: EventTimeReport[], i: EventTimeReport) => {
      let matches = 0;
      search.forEach((s: string) => {
        let props = 0;
        const match = s.toLowerCase();
        const keys = Object.entries(i).reduce((acc: string[], curr) => {
          return curr && typeof curr[1] === 'string' ? [...acc, curr[0]] : acc;
        }, []);

        keys.forEach((k: string) => {
          if ((i[k as ReportTypes] as string).indexOf(match) !== -1) {
            props++;
          }
        });
        if (props >= 1) {
          matches++;
        }
      });
      if (matches === search.length) {
        found.push(i);
      }
      return found;
    }, []);
    return ret;
  };

  const formattedActivity = filter.length > 0 ? fuzzySearch(filter) : activity;
  const orderedActivityList = invertList.key
    ? sortByKey(formattedActivity, invertList.key, invertList.invert)
    : formattedActivity;

  const paginator = (page_num: number, per_page: number, arr: EventTimeReport[]) => {
    const offset = page_num * per_page;
    const paginatedItems = arr.slice(offset).slice(0, per_page);
    const totalPages = Math.ceil(formattedActivity.length / per_page - 1);

    return {
      data: paginatedItems ?? [],
      total: totalPages,
    };
  };

  const displayInvertState = (row: string) => {
    const { invert, key } = invertList;
    if (invert && row === key) {
      return true;
    }

    return false;
  };

  const tableHeader = [
    { label: t('activity_log_table_datetime'), key: 'datetime' },
    { label: t('activity_log_table_action'), key: 'action' },
    { label: t('activity_log_table_clientCountryName'), key: 'clientCountryName' },
    { label: t('activity_log_table_ip'), key: '', hideAction: true },
    { label: t('activity_log_table_service'), key: '', hideAction: true },
  ];

  return (
    <StyledCard>
      <CardHeader title={t('activity_log_card_header')} />
      <CardContent>
        <Box className={classes.search}>
          <TextField
            placeholder="Search"
            value={filter}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disableTypography component="button">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((header: TableHeader, i: number) => (
                  <TableCell key={`${i}-${header.key}`}>
                    <Box component="span">
                      {header.label}
                      {!header.hideAction && (
                        <Button
                          onClick={() =>
                            setInvertList({
                              key: header.key,
                              invert: !invertList.invert,
                            })
                          }
                          color="primary"
                        >
                          {displayInvertState(header.key) ? <ArrowDropUp /> : <ArrowDropDown />}
                        </Button>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <ActivityTableRowSkeleton />
              ) : (
                paginator(page, perPage, orderedActivityList).data.map(
                  (a: EventTimeReport, i: number) => (
                    <ActivityTableRow invertList={invertList} key={i} {...a} />
                  )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.pagination}>
          <Select
            name="perPage"
            value={perPage}
            onChange={(event: SelectChangeEvent<string | number>) =>
              setPerPage(Number(event.target.value))
            }
            variant="outlined"
          >
            <MenuItem value="10">{t('per_page', { count: 10 })}</MenuItem>
            <MenuItem value="50">{t('per_page', { count: 50 })}</MenuItem>
            <MenuItem value="100">{t('per_page', { count: 100 })}</MenuItem>
          </Select>
          <Pagination
            onChange={(event: object, newPage: number) => setPage(newPage)}
            count={paginator(page, perPage, orderedActivityList).total}
          />
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ActivityLog;
