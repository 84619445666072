import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './types';
import { AxiosService } from 'api/axiosService';

interface CliData {
  command: string;
  action?: string;
  value?: string;
  params?: string;
}

interface CliResponse {
  response: string;
}

export const useCliCommand = (siteId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`cliCommand-${siteId}`],

    mutationFn: async (data: CliData) =>
      await AxiosService.post<ApiResponse<CliResponse>>(`sites/${siteId}/cli`, data),

    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [`cliCommand-${siteId}`],
      }),
  });
};
