import { FC } from 'react';
import { styled } from '@mui/material/styles';
import EventGraph from './components/EventGraph';
import ChartByType from './components/ChartByType';
import EventSources from './components/EventSources';
import { useGetFirewallEvents } from 'api/siteMetrics';
import { loadingContainer } from 'theme/custom/loading-container';
import { noData } from 'theme/custom/no-data';
import { Box, Typography } from '@mui/material';
import ReportingNoData from 'component/svg/chart/no-data.svg?react';
import { CircularProgress } from 'component/base/CircularProgress';
import { useTranslation } from 'react-i18next';

const PREFIX = 'WAFReport';

const classes = {
  loadingContainer: `${PREFIX}LoadingContainer`,
  loadingContainerProgress: `${PREFIX}LoadingContainerProgress`,
  noData: `${PREFIX}NoData`,
};

const Root = styled('div')({
  [`& .${classes.loadingContainer}`]: {
    ...loadingContainer,
    height: '21.875rem',
  },
  [`& .${classes.loadingContainerProgress}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  [`& .${classes.noData}`]: {
    ...noData,
  },
});

interface WAFReportProps {
  readonly siteId: string;
  readonly duration: string;
}

const WAFReport: FC<WAFReportProps> = ({ siteId, duration }) => {
  const firewallEvents = useGetFirewallEvents(siteId, duration);

  const series = firewallEvents.data?.data?.data?.viewer?.zones[0]?.series ?? [];
  const { t } = useTranslation();

  if (firewallEvents.isLoading) {
    return (
      <Root>
        <Box className={classes.loadingContainer}>
          <Box className={classes.loadingContainerProgress}>
            <CircularProgress />
          </Box>
        </Box>
      </Root>
    );
  }

  if ((series === undefined || series.length === 0) && !firewallEvents.isLoading) {
    return (
      <Root>
        <Box className={classes.loadingContainer}>
          <Box className={classes.noData}>
            <ReportingNoData />
            <Typography variant="h3">{t('no_data_to_display')}</Typography>
            <Typography>{t('no_data_to_display_description')}</Typography>
          </Box>
        </Box>
      </Root>
    );
  }

  return (
    <Root>
      <EventGraph siteId={siteId} duration={duration} query={firewallEvents} />
      <ChartByType siteId={siteId} duration={duration} />
      <EventSources siteId={siteId} duration={duration} />
    </Root>
  );
};

export default WAFReport;
