import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Button,
  Typography,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useSnackbar } from 'component/hooks/useSnackbar';

import { useReducer } from 'react';
import {
  usePluginList,
  useDeletePlugin,
  ActivatePayload,
  useChangeActiveStatus,
  UpgradePayload,
  DeletePayload,
  useUpdatePlugin,
} from 'api/plugins';
import { useParams } from 'react-router';
import { Plugin } from 'api/plugins';
import { sortByKey } from 'utils/sort';

import CardCta from './components/CardCta';
import PluginRow from './components/PluginRow';
import PluginRowSkeleton from './skeletons/PluginRow';
import NoResultsFound from 'component/base/NoResultsTableRow';
import NoPluginsInstalled from './components/NoPluginsInstalled';
import { AddNewPlugin } from 'component/partial/AddNewPlugin';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import ContentViewCard from 'component/base/ContentViewCard';

import paginate from 'utils/paginate';
import PaginationControls from 'component/base/PaginationControls';
import { useTranslation } from 'react-i18next';
import { loadDefaultPerPage } from 'utils/paginate';

enum PluginActions {
  TOGGLE_LOADING = 'toggle loading',
  TOGGLE_INVERT_LIST = 'invert list',
  DELETE_PLUGIN = 'delete plugin',
  RESET_STATE = 'reset state',
  FILTER_LIST = 'filter plugin list',
  SET_ACTIVE_PAGE = 'set active page',
  SET_PER_PAGE = 'set per page',
}
interface PluginsListState {
  filter: string;
  activePageNumber: number;
  perPage: 10 | 50 | 100;
  loading: boolean;
  invertList: boolean;
  confirmModal: null | DeletePayload | ActivatePayload | UpgradePayload;
}
type PerPage = 10 | 50 | 100;

const initialState: PluginsListState = {
  filter: '',
  activePageNumber: 1,
  perPage: loadDefaultPerPage() as PerPage,
  loading: false,
  invertList: false,
  confirmModal: null,
};

const reducer = (state: PluginsListState, [type, payload]: any): PluginsListState => {
  switch (type) {
    case PluginActions.TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading as boolean,
      };
    case PluginActions.RESET_STATE:
      return initialState;
    case PluginActions.FILTER_LIST:
      return {
        ...state,
        filter: payload as string,
      };
    case PluginActions.TOGGLE_INVERT_LIST:
      return {
        ...state,
        invertList: !state.invertList as boolean,
      };
    case PluginActions.DELETE_PLUGIN:
      return {
        ...state,
        confirmModal: payload as DeletePayload | ActivatePayload | UpgradePayload | null,
      };
    case PluginActions.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePageNumber: payload,
      };
    case PluginActions.SET_PER_PAGE:
      return {
        ...state,
        perPage: payload,
      };
    default:
      return state;
  }
};

export default function Plugins() {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { data, status, refetch } = usePluginList(siteId);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [
    { filter, activePageNumber, perPage, invertList, confirmModal, loading: pageLoading },
    dispatch,
  ] = useReducer(reducer, initialState);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch([PluginActions.FILTER_LIST, value]);
  };

  const plugins: Plugin[] = data?.data?.result || [];
  const filteredPluginsList =
    filter.length > 0
      ? plugins.filter(
          g => g.name.replace('-', ' ').toLowerCase().indexOf(filter.toLowerCase()) !== -1
        )
      : plugins;
  const orderedPluginsList = sortByKey(filteredPluginsList, 'name', invertList);
  const pluginSubheaderText = plugins.length === 0 ? 'No Plugins Installed' : `| ${plugins.length}`;

  const showModal = (plugin: DeletePayload | ActivatePayload | UpgradePayload) => {
    dispatch([PluginActions.DELETE_PLUGIN, plugin]);
  };

  const { status: deleteStatus, mutateAsync: deleteAsync } = useDeletePlugin(siteId);
  const { status: activeStatus, mutateAsync: activeAsync } = useChangeActiveStatus(siteId);
  const { status: updateStatus, mutateAsync: updateAsync } = useUpdatePlugin(siteId);

  const isDeleteModal = Boolean((confirmModal as DeletePayload)?.plugins !== undefined);
  const isUpdateModal = Boolean((confirmModal as UpgradePayload)?.update !== undefined);

  const isModalLoading =
    deleteStatus === 'pending' ||
    pageLoading ||
    activeStatus === 'pending' ||
    updateStatus === 'pending';

  const handleConfirmChange = async () => {
    if (!confirmModal) return false;
    dispatch([PluginActions.TOGGLE_LOADING]);

    const { title, ...modalData } = confirmModal;

    try {
      if (isDeleteModal) {
        await deleteAsync(modalData as DeletePayload);
      }

      if (isUpdateModal) {
        await updateAsync(modalData as UpgradePayload);
      }

      if (!isDeleteModal && !isUpdateModal) {
        await activeAsync(modalData as ActivatePayload);
      }

      if ('status' in modalData) {
        enqueueSnackbar(
          t('plugin_has_been_updated', {
            title,
            updateType: modalData.status === 'activate' ? t('activated') : t('deactivated'),
          }),
          {
            key: 'pluginUpdate',
            variant: 'success',
          }
        );
      }

      await refetch();
    } catch (err) {
      // TODO: figure out global error messaging
    } finally {
      dispatch([PluginActions.RESET_STATE]);
    }
  };

  const renderTableRows = () => {
    if (status === 'pending') {
      return <PluginRowSkeleton />;
    }

    const pluginList = paginate(orderedPluginsList, perPage, activePageNumber);
    return pluginList.map((plugin: Plugin) => (
      <PluginRow key={`${plugin.name}-${plugin.version}`} data={plugin} showModal={showModal} />
    ));
  };

  const modalData = () => {
    const type = () => {
      if (isUpdateModal) {
        return 'Update';
      }
      if (isDeleteModal) {
        return 'Delete';
      }
      return (confirmModal as ActivatePayload)?.status === 'activate' ? 'Activate' : 'Deactivate';
    };

    const title = `${type()} Plugin`;
    const desc = `Are you sure you want to ${type().toLowerCase()} ${confirmModal?.title}?`;

    return {
      title,
      desc,
      confirmButtonText: type(),
    };
  };

  const handlePaginationChange = (newPage: number) => {
    dispatch([PluginActions.SET_ACTIVE_PAGE, newPage]);
  };

  const handlePerPage = (value: number) => {
    dispatch([PluginActions.SET_PER_PAGE, value]);
  };

  return (
    <>
      <ContentViewCard
        title={
          <Typography variant="h2">
            Installed Plugins {data ? <Box component="span">{pluginSubheaderText}</Box> : null}
          </Typography>
        }
        testId="pluginsList"
      >
        {status === 'success' && plugins.length === 0 ? (
          <NoPluginsInstalled />
        ) : (
          <>
            <CardCta handleFilter={handleFilter} filter={filter} />
            <TableContainer>
              <Table aria-label="Plugins List Table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box component="span">
                        Name
                        <Button
                          onClick={() => dispatch([PluginActions.TOGGLE_INVERT_LIST])}
                          color="primary"
                        >
                          {invertList ? <ArrowDropUp /> : <ArrowDropDown />}
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell width={172}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter !== '' && filteredPluginsList.length === 0 ? (
                    <NoResultsFound colSpan={4} />
                  ) : (
                    renderTableRows()
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationControls
              totalRowCount={filteredPluginsList.length}
              perPage={perPage}
              onPageChange={handlePaginationChange}
              onPerPageChange={handlePerPage}
            />
          </>
        )}
      </ContentViewCard>

      <AddNewPlugin siteId={siteId} />

      <ConfirmationDialog
        action={isDeleteModal ? 'delete' : 'confirm'}
        forceLoadingState={isModalLoading}
        description={modalData().desc}
        onClose={() => dispatch([PluginActions.RESET_STATE])}
        open={Boolean(confirmModal !== null)}
        onConfirm={handleConfirmChange}
        title={modalData().title || ''}
        confirmText={modalData().confirmButtonText}
      />
    </>
  );
}
