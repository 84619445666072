export const sortByKey = <T>(list: Array<T>, key: keyof T, inverted?: boolean): Array<T> => {
  return list.sort((a, b) => {
    const x = a[key];
    const y = b[key];

    if (inverted) {
      return x > y ? -1 : x < y ? 1 : 0;
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

type Datum = {
  y: number;
  x: string;
};

export function sortSeriesByDate(a: Datum, b: Datum) {
  const key1 = new Date(a.x);
  const key2 = new Date(b.x);

  if (key1 < key2) {
    return -1;
  } else if (key1 === key2) {
    return 0;
  } else {
    return 1;
  }
}
