import { useState } from 'react';
import PaginationControls from 'component/base/PaginationControls';
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Skeleton,
} from '@mui/material';
import { useParams } from 'react-router';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { DeleteRounded } from '@mui/icons-material';
import { useListPasswordProtectionStatusUsers, useDeleteSiteProtectionUser } from 'api/security';
import { CreateUser } from './CreateUser';
import { loadDefaultPerPage } from 'utils/paginate';

interface PaginationState {
  perPage: number;
  activePageNumber: number;
  filter: string;
}

export interface ConfirmationModal {
  open: boolean;
  userId: string | null;
  username: string | null;
  type: 'delete';
}

const initConfirmation: ConfirmationModal = {
  open: false,
  userId: null,
  username: null,
  type: 'delete',
};

function RowsSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

const UserList = () => {
  const { t } = useTranslation();
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [pagination, setPagination] = useState<PaginationState>({
    perPage: loadDefaultPerPage(),
    activePageNumber: 1,
    filter: '',
  });
  const { data, isLoading } = useListPasswordProtectionStatusUsers({ siteId });
  const users = data?.data.result.users ?? [];

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initConfirmation);
  const { mutateAsync: deleteUser, isPending: deleteLoading } = useDeleteSiteProtectionUser({
    siteId,
  });
  const handleDeleteUser = async () => {
    if (confirmationModal.userId) {
      await deleteUser(confirmationModal.userId);
      setConfirmationModal(initConfirmation);
      enqueueSnackbar(t('security_delete_user_success'), { variant: 'success' });
    }
  };

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (perPage: number) => {
    setPagination({ ...pagination, activePageNumber: 1, perPage });
  };

  const closeModal = () => setConfirmModal(false);

  const renderConfirmationDescription = () => {
    if (confirmationModal.type === 'delete') {
      return t('security_delete_user_confirmation', { username: confirmationModal.username });
    } else {
      return null;
    }
  };

  return (
    <>
      <Box alignSelf="end">
        <Button onClick={() => setConfirmModal(true)} variant="contained" color="primary">
          {t('add_user')}
        </Button>
      </Box>
      <TableContainer
        sx={{
          marginTop: '0px',
        }}
      >
        <Table aria-label={'users table'}>
          <TableHead>
            <TableRow>
              <TableCell>{t('username')}</TableCell>
              <TableCell width="90px">{t('action')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <RowsSkeleton />
            ) : users?.length === 0 ? (
              <NoResultsFound colSpan={2} />
            ) : (
              users.map(user => {
                return (
                  <TableRow key={user.user_id}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          setConfirmationModal({
                            open: true,
                            type: 'delete',
                            userId: user.user_id,
                            username: user.username,
                          })
                        }
                        color="primary"
                        size="large"
                      >
                        <DeleteRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!isLoading && (
        <PaginationControls
          totalRowCount={users.length}
          perPage={pagination.perPage}
          onPageChange={handlePaginationChange}
          onPerPageChange={handlePerPage}
        />
      )}
      <CreateUser siteId={siteId} open={confirmModal} onClose={closeModal} />

      <ConfirmationDialog
        action={confirmationModal.type === 'delete' ? 'delete' : 'confirm'}
        forceLoadingState={Boolean(deleteLoading)}
        onClose={() => setConfirmationModal(initConfirmation)}
        open={confirmationModal.open}
        onConfirm={confirmationModal.type === 'delete' ? handleDeleteUser : undefined}
        title={(confirmationModal.type === 'delete' && t('security_delete_user')) ?? ''}
        description={renderConfirmationDescription()}
      />
    </>
  );
};

export default UserList;
