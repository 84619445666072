import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';

interface Props {
  readonly name: string;
  readonly email: string;
}

export const Gravatar = ({ name, email }: Props) => {
  const [hash, setHash] = useState<string>('');

  useEffect(() => {
    (async () => {
      const data = new TextEncoder().encode(email);
      const digest = await crypto.subtle.digest('SHA-256', data);
      setHash(
        Array.from(new Uint8Array(digest))
          .map(b => b.toString(16).padStart(2, '0'))
          .join('')
      );
    })();
  }, [email]);

  const gravatarUrl = hash ? `https://www.gravatar.com/avatar/${hash}?d=mp` : undefined;

  return <Avatar alt={name} src={gravatarUrl} />;
};
