import { FC, useEffect, useState, useRef } from 'react';
import { TableRow, TableCell, Grid, Tooltip, IconButton, CircularProgress } from '@mui/material';
import { VerticalAlignBottomRounded, RestoreRounded, DeleteRounded } from '@mui/icons-material';
import { Backup, useGetDownloadLink, usePollTask, useRestoreBackup } from 'api/backups';
import { formatDate } from 'utils/dateFormat';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';

type LocalBackupStatus = Backup['status'] | 'RESTORE';
type BackupWithLocalStatus = Omit<Backup, 'status'> & { status?: LocalBackupStatus };

interface InstalledTableRowProps {
  readonly backup: Backup;
  readonly siteId: string;
  readonly refreshList: () => void;
  readonly openConfirmationModal: (
    backupid: number,
    type?: 'delete' | 'restore',
    onSubmit?: () => Promise<unknown>
  ) => void;
}

const InstalledTableRow: FC<InstalledTableRowProps> = ({
  backup,
  refreshList,
  siteId,
  openConfirmationModal,
}: InstalledTableRowProps) => {
  const [row, setRow] = useState<BackupWithLocalStatus | null>(null);
  const restoreId = useRef<string | null>(null);

  useEffect(() => setRow(backup), [backup]);

  const [pollEnabled, setPollEnabled] = useState<boolean>(false);
  const pollId = restoreId.current?.length ? restoreId.current : row ? row.task_id : '';
  const { data } = usePollTask(siteId, pollId!, pollEnabled || !!restoreId.current?.length);
  const { mutateAsync: getDownloadLink, isPending: getDownloadLinkLoading } =
    useGetDownloadLink(siteId);
  let pollResult = data?.data.result ?? undefined;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { mutateAsync: restoreBackup, isPending: isRestoring } = useRestoreBackup(siteId);
  useEffect(() => {
    if ((row && row.status === 'PROGRESS') || (row && row.status === 'NEW')) {
      setPollEnabled(true);
    } else {
      setPollEnabled(false);
    }
  }, [row, isRestoring]);

  const snackMessage = (): string | null => {
    switch (row?.status) {
      case 'NEW':
        return t('backup_created_successfully');
      default:
        return null;
    }
  };

  useEffect(() => {
    if (pollResult && pollResult[0].task_status === 'DONE') {
      setPollEnabled(false);
      refreshList();
      pollResult = undefined;
      restoreId.current = null;

      if (row && snackMessage()) {
        enqueueSnackbar(snackMessage(), {
          key: 'installBackupSuccess',
          variant: 'success',
        });
      }
    }

    if (
      pollResult &&
      pollResult[0].task_status === 'PROGRESS' &&
      (pollResult[0].id === row?.task_id || pollResult[0].id === restoreId.current)
    ) {
      setRow({
        ...row,
        status: (pollResult[0].task_type === 'restore' ? 'RESTORE' : 'NEW') as any,
      });
    }
  }, [pollResult]);

  const handleRestoreBackup = async () => {
    try {
      setRow({ ...row, status: 'RESTORE' });

      const res = await restoreBackup({
        backup_database: true,
        backup_directory: true,
        backup_id: String(row?.id),
      });

      restoreId.current = res.data.result?.task_id || null;
      setPollEnabled(true);
    } catch (error) {
      setRow({ ...row, status: 'ERROR' });
      throw error;
    }
  };

  const backupStatus = (status: LocalBackupStatus) => {
    switch (status) {
      case 'PROGRESS':
        return 'Processing';
      case 'NEW':
        return 'Creating';
      case 'ERROR':
        return 'Error';
      case 'RESTORE':
        return 'Restoring';
      default:
        return 'Available';
    }
  };

  if (!row) return null;

  const { status, created, label, id, task_id } = row;

  return (
    <TableRow key={created}>
      <TableCell width="40%">{label}</TableCell>
      <TableCell>{backupStatus(status)}</TableCell>
      <TableCell>{formatDate({ date: created! })}</TableCell>
      <TableCell width="15%">
        <Grid container justifyContent="space-between" direction="row" wrap="nowrap">
          <Grid item>
            <Tooltip arrow placement="top" title={t('backup_tooltip', { type: t('download') })}>
              <IconButton
                onClick={async () => {
                  const resp = await getDownloadLink(task_id!);
                  const downloadLink = resp.data.result?.[0].download_link;

                  if (downloadLink) {
                    const link = document.createElement('a');
                    link.setAttribute('download', `${id}-backup-link`);
                    link.href = downloadLink;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }
                }}
                disabled={status !== 'DONE'}
                size="large"
              >
                {getDownloadLinkLoading ? (
                  <CircularProgress
                    sx={{
                      width: 'auto !important',
                      height: 'auto !important',
                    }}
                  />
                ) : (
                  <VerticalAlignBottomRounded />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              placement="top"
              title={t('backup_tooltip', { type: t('backup_label_restore') })}
            >
              <IconButton
                onClick={() => openConfirmationModal(id!, 'restore', handleRestoreBackup)}
                disabled={status !== 'DONE'}
                size="large"
              >
                <RestoreRounded />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              placement="top"
              title={t('backup_tooltip', { type: t('backup_label_delete') })}
            >
              <IconButton
                onClick={() => openConfirmationModal(id!)}
                disabled={status !== 'DONE'}
                size="large"
              >
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default InstalledTableRow;
