import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, TextField, InputAdornment, Button } from '@mui/material';
import { SearchRounded, FilterListRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const PREFIX = 'CardCta';

const classes = {
  textField: `${PREFIX}TextField`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.textField}`]: {
    '@media (min-width: 37.5rem)': {
      maxWidth: '18.75rem',
    },
  },
});

interface CardCtaProps {
  readonly handleFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly filter: string;
  readonly label?: string;
  readonly action?: () => unknown;
  readonly actionLabel?: string;
}

const CardCta: FC<CardCtaProps> = ({ handleFilter, filter, action, label, actionLabel }) => {
  const { t } = useTranslation();

  return (
    <StyledGrid container spacing={3} justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={8}>
        <TextField
          onChange={handleFilter}
          fullWidth
          placeholder={label ?? t('search_plugins')}
          variant="outlined"
          value={filter}
          className={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" disableTypography component="button">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {action ? (
        <Grid item>
          <Button
            onClick={action}
            color="primary"
            variant="outlined"
            startIcon={<FilterListRounded />}
          >
            {actionLabel}
          </Button>
        </Grid>
      ) : null}
    </StyledGrid>
  );
};

export default CardCta;
