import { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Select, MenuItem, CircularProgress, SelectChangeEvent } from '@mui/material';
import { ChangeDataSchema } from './SettingsCard';

const PREFIX = 'ChangeSelect';

const classes = {
  progress: `${PREFIX}Progress`,
};

const StyledSelect = styled(Select)({
  [`& .${classes.progress}`]: {
    verticalAlign: 'middle',
  },
});

export interface ChangeSelectProps {
  readonly name: string;
  readonly options: { name: string; value: string | number }[];
  readonly onChange: (changedValue: ChangeDataSchema) => Promise<unknown>;
  readonly currentValue: string | number | boolean;
}

const ChangeSelect: FC<ChangeSelectProps> = ({ name, options, onChange, currentValue }) => {
  const [value, setValue] = useState(currentValue);
  const [loading, setLoading] = useState(false);

  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    setValue(event.target.value as string);
    setLoading(true);
    try {
      await onChange({
        key: name,
        value: event.target.value as string,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledSelect variant="outlined" onChange={handleChange} value={value} disabled={loading}>
      {options.map(({ name, value }, i: number) => (
        <MenuItem key={i} value={value}>
          {name}{' '}
          {loading ? (
            <Box marginLeft={1}>
              <CircularProgress size={16} className={classes.progress} />
            </Box>
          ) : null}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default ChangeSelect;
