import { useState } from 'react';
import { Button } from '@mui/material';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import ClearCacheForm from './Form';
import { usePurgeAllCache, usePurgeSpecificPaths } from 'api/site';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';

interface ClearCacheData {
  clearType: 'all' | 'paths';
  paths: undefined | string;
}

interface ClearCacheProps {
  readonly siteId: string;
}

const ClearCache: FC<ClearCacheProps> = ({ siteId }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<ClearCacheData>({
    defaultValues: {
      clearType: 'all',
      paths: undefined,
    },
  });
  const { watch, reset } = methods;
  const { clearType, paths } = watch();

  const { mutateAsync: purgeAll, isPending: isPurgeAllLoading } = usePurgeAllCache(siteId);
  const { mutateAsync: purgeSpecific, isPending: isPurgeSpecificLoading } =
    usePurgeSpecificPaths(siteId);

  const onSubmit = async () => {
    if (clearType === 'all') {
      await purgeAll();
    }

    if (clearType === 'paths' && paths) {
      const requestData = paths.replace(/,/g, '').split('\n');
      await purgeSpecific({ files: requestData });
    }

    setOpen(false);
    enqueueSnackbar(t('purge_cache_completed'), {
      key: 'purgeCompleted',
      variant: 'success',
    });
    reset();
  };

  const confirmButtonDisabled = () => {
    if (clearType === 'all') {
      return false;
    }
    if (clearType === 'paths' && !paths) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<DeleteOutlineRounded />}
        variant="outlined"
        color="primary"
        fullWidth
      >
        Clear Cache
      </Button>

      <ConfirmationDialog
        action="confirm"
        onConfirm={onSubmit}
        forceLoadingState={isPurgeSpecificLoading || isPurgeAllLoading}
        confirmDisabled={confirmButtonDisabled()}
        onClose={() => setOpen(false)}
        open={open}
        title={t('clear_cache')}
        description={
          <FormProvider {...methods}>
            <ClearCacheForm onSubmit={onSubmit} clearType={clearType} />
          </FormProvider>
        }
        confirmText={String(clearType === 'all' ? t('clear_all_cache') : t('clear_specific_paths'))}
      />
    </>
  );
};

export default ClearCache;
