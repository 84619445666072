import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { components } from 'openapi-types';

export type SSHKeyList = NonNullable<components['schemas']['ListSSHKeysResponse']['result']>;

export const useListSSHKeys = (siteId: string) => {
  return useQuery({
    queryKey: [`ssh-keys-${siteId}`],

    queryFn: async () =>
      AxiosService.get<components['schemas']['ListSSHKeysResponse']>(`sites/${siteId}/ssh/keys`),
  });
};

export const useViewSSHInfo = (siteId: string, name: string) => {
  return useQuery({
    queryKey: [`ssh-keys-info-${siteId}`],
    queryFn: async () =>
      AxiosService.get<components['schemas']['GetSSHKeyResponse']>(
        `sites/${siteId}/ssh/keys/${name}`
      ),
    enabled: false,
  });
};

export const useDeactivateSSHKey = (siteId: string) => {
  return useMutation({
    mutationKey: [`ssh-keys-${siteId}`],

    mutationFn: async (name: string) =>
      AxiosService.post<components['schemas']['NameStatusResponse']>(
        `sites/${siteId}/ssh/keys/deauthorize`,
        { name }
      ),
  });
};

export const useActivateSSHKey = (siteId: string) => {
  return useMutation({
    mutationKey: [`ssh-keys-${siteId}`],

    mutationFn: async (name: string) =>
      AxiosService.post<components['schemas']['NameStatusResponse']>(
        `sites/${siteId}/ssh/keys/authorize`,
        { name }
      ),
  });
};

export const useDeleteSSHKey = (siteId: string) => {
  return useMutation({
    mutationKey: [`ssh-keys-${siteId}`],

    mutationFn: async (name: string) =>
      AxiosService.delete<components['schemas']['NameStatusResponse']>(`sites/${siteId}/ssh/keys`, {
        data: { name },
      }),
  });
};

export type ImportSSHKey = components['schemas']['ImportSSHKeyRequest'];

export const useImportSSHKey = (siteId: string) => {
  return useMutation({
    mutationKey: [`site-settings-${siteId}`],

    mutationFn: async (body: ImportSSHKey) =>
      await AxiosService.post<components['schemas']['NameResponse']>(
        `sites/${siteId}/ssh/keys`,
        body
      ),
  });
};
