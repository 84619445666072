import { CircularProgress as MuiCircularProgress } from '@mui/material';

import { styled } from '@mui/material/styles';
const PREFIX = 'CircularProgress';

const classes = {
  circularProgress: `${PREFIX}CircularProgress`,
};

const StyledMuiCircularProgress = styled(MuiCircularProgress)({
  [`&.${classes.circularProgress}`]: (props: Props) => ({
    left: '50%',
    margin: '-1.25rem 0 0 -1.25rem',
    position: props.position ?? 'fixed',
    top: '50%',
  }),
});

interface Props {
  readonly position?: 'absolute' | 'fixed';
}

export function CircularProgress(props: Props) {
  return <StyledMuiCircularProgress className={classes.circularProgress} {...props} />;
}
