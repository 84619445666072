import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'LegendDot';

const classes = {
  root: `${PREFIX}Root`,
};

const Root = styled(Box)({
  [`&.${classes.root}`]: {
    '& svg': {
      verticalAlign: 'middle',
      fontSize: '0.875em',
    },
  },
});

type Props = {
  readonly color: string;
};

export function LegendDot({ color }: Props) {
  return (
    <Root className={classes.root} sx={{ color, display: 'inline-block', marginRight: '0.313rem' }}>
      <FiberManualRecordIcon color="inherit" />
    </Root>
  );
}
