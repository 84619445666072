import { Button, Collapse, TableRow, TableCell, Typography } from '@mui/material';
import { EventTimeReport } from 'api/siteMetrics';
import { FC, useEffect, useState } from 'react';
import { eventLabels, eventTypes } from './ChartByType';
import ActivityRowExpanded from './ActivityRowExpanded';
import { InvertState } from '../ActivityLog';
import CountryName from '../../../../base/CountryName';
import { useTranslation } from 'react-i18next';

interface ActivityTableRowProps extends EventTimeReport {
  readonly invertList: InvertState;
}

const ActivityTableRow: FC<ActivityTableRowProps> = ({
  datetime,
  action,
  clientCountryName,
  clientIP,
  source,
  clientRequestHTTPProtocol,
  clientRequestQuery,
  clientRequestPath,
  clientRequestHTTPHost,
  clientAsn,
  userAgent,
  rayName,
  clientRequestHTTPMethodName,
  invertList,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(false);
  }, [invertList]);

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const options = {
      dateStyle: 'medium',
      timeStyle: 'short',
    };
    const formatted = new Intl.DateTimeFormat('en-US', options as object).format(newDate);
    return formatted;
  };

  const actionType = (action: string) => {
    switch (action) {
      case 'jschallenge':
        return t('actvitity_type_jschallenge');
      case 'block':
        return t('actvitity_type_block');
      default:
        return t('actvitity_type_allow');
    }
  };

  const serviceLabels = {
    firewallrules: t('activity_firewallrules_label'),
    waf: t('activity_bic_label'),
    bic: t('activity_ip_label'),
    ip: t('activity_waf_label'),
  } as eventLabels;

  const rowData = {
    clientRequestHTTPProtocol,
    clientRequestQuery,
    clientRequestPath,
    clientRequestHTTPHost,
    clientAsn,
    userAgent,
    rayName,
    clientRequestHTTPMethodName,
  };

  return (
    <>
      <TableRow className={open ? 'is-active' : undefined}>
        <TableCell>
          <Typography noWrap>
            <Button onClick={() => setOpen(!open)} color="primary">
              {formatDate(datetime)}
            </Button>
          </Typography>
        </TableCell>
        <TableCell>{actionType(action)}</TableCell>
        <TableCell>
          <CountryName countryCode={clientCountryName} />
        </TableCell>
        <TableCell>{clientIP}</TableCell>
        <TableCell>{serviceLabels[source as eventTypes]}</TableCell>
      </TableRow>
      <TableRow className="is-collapse">
        <TableCell colSpan={5}>
          <Collapse in={open} unmountOnExit>
            <ActivityRowExpanded data={rowData} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ActivityTableRow;
