import { useParams } from 'react-router-dom';
import InstalledThemes from './components/InstalledThemes';
import { AddNewTheme } from 'component/partial/AddNewTheme';

export const WpThemes: React.FC = () => {
  const { siteId = '' } = useParams<{ siteId: string }>();

  return (
    <>
      <InstalledThemes siteId={siteId} />
      <AddNewTheme siteId={siteId} />
    </>
  );
};

export default WpThemes;
