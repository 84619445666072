import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';

const PREFIX = 'ThemeSkeleton';

const classes = {
  image: `${PREFIX}Image`,
};

const Root = styled('div')({
  [`& .${classes.image}`]: {
    marginBottom: '0.625rem',
    position: 'relative',
    '& span': {
      display: 'block',
      paddingTop: '75%',
    },
  },
});

const ThemeSkeleton: React.FC = () => {
  const rows = () => {
    return (
      <Root>
        {Array.from({ length: 12 }).map((v, i) => (
          <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
            <Box className={classes.image}>
              <Skeleton variant="rectangular" />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Skeleton height={36} />
              </Grid>
              <Grid item xs={10}>
                <Skeleton height={36} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Root>
    );
  };
  return <>{rows()}</>;
};
export default ThemeSkeleton;
