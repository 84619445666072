import { TableRow, TableCell, Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

export default function InstalledThemeRowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <Skeleton width={36} />
            </Grid>
            <Grid item>
              <Skeleton width={36} />
            </Grid>
            <Grid item>
              <Skeleton width={36} />
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}
