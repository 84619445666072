import { Skeleton } from '@mui/material';
import { Grid, TableRow, TableCell } from '@mui/material';

const SSHKeyySkeleton = () => {
  return (
    <>
      {Array.from({ length: 2 }).map((v, i) => (
        <TableRow key={i}>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Skeleton width={36} />
              </Grid>
              <Grid item>
                <Skeleton width={36} />
              </Grid>
              <Grid item>
                <Skeleton width={36} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default SSHKeyySkeleton;
