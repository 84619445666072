import { FC, useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz, Settings, Launch, Done } from '@mui/icons-material';
import { SearchThemesResultItem, useInstallTheme } from 'api/themes';
import LoadingContainer from '../LoadingContainer';
import { isInIframe } from 'utils/iframe';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

const PREFIX = 'ThemeCard';

const classes = {
  image: `${PREFIX}Image`,
  title: `${PREFIX}Title`,
  installed: `${PREFIX}Installed`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.image}`]: {
    marginBottom: '0.625rem',
    position: 'relative',
    '& img': {
      display: 'block',
      width: '100%',
    },
  },
  [`& .${classes.title}`]: {
    alignItems: 'center',
    display: 'flex',
    '& button': {
      marginRight: '0.3125rem',
    },
    '& h3': {
      fontWeight: 600,
    },
  },
  [`& .${classes.installed}`]: {
    color: 'var(--color-pistachio) !important',
  },
});

interface ThemeCardProps {
  readonly theme: SearchThemesResultItem;
  readonly setInstallName: (name: string) => void;
  readonly installingTheme: string;
  readonly isInstalled: boolean;
}

const ThemeCard: FC<ThemeCardProps> = ({ theme, installingTheme, setInstallName, isInstalled }) => {
  const { name, title, screenshot_url, preview_url } = theme;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const { isPending, mutateAsync, isError, isSuccess } = useInstallTheme(siteId);
  const { sendMessage } = useContext(PostMessageContext);

  const handleInstallTheme = async () => {
    setAnchorEl(null);
    setInstallName(name!);
    await mutateAsync({
      themes: name,
      activate: true,
    });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isInstalled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleVisitPage = () => {
    if (isInIframe()) {
      sendMessage(
        JSON.stringify({
          type: 'externalLink',
          data: {
            link: preview_url,
          },
        })
      );
    } else {
      window.open(preview_url);
    }

    setAnchorEl(null);
  };

  const actionRunning = isPending && installingTheme === name;

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        t('install_theme_error', {
          themeName: name,
        }),
        {
          key: 'installThemeError',
          variant: 'error',
        }
      );
    }

    if (isSuccess) {
      enqueueSnackbar(
        t('install_theme_success', {
          themeName: name,
        }),
        {
          key: 'installThemeSuccess',
          variant: 'success',
          persist: true,
        }
      );
    }
  }, [isError, isSuccess]);

  return (
    <StyledGrid item xs={12} sm={6} md={4} lg={3}>
      <Box className={classes.image}>
        {actionRunning ? <LoadingContainer /> : null}
        <img src={screenshot_url} alt={name} />
      </Box>
      <Box className={classes.title}>
        <IconButton
          onClick={handleMenu}
          aria-controls={`theme-menu-${name}`}
          aria-haspopup="true"
          color="primary"
          disabled={isInstalled ? true : false}
          className={isInstalled ? classes.installed : ''}
          size="large"
        >
          {isInstalled ? <Done color="inherit" /> : <MoreHoriz />}
        </IconButton>
        <Typography variant="h3">{title}</Typography>
      </Box>
      {!isInstalled && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          id={`theme-menu-${name}`}
        >
          <MenuItem disabled={actionRunning} onClick={handleInstallTheme}>
            <Settings />
            <Typography>{t('install_theme')}</Typography>
          </MenuItem>
          <MenuItem onClick={handleVisitPage} disabled={actionRunning}>
            <Launch />
            <Typography>{t('visit_theme_page')}</Typography>
          </MenuItem>
        </Menu>
      )}
    </StyledGrid>
  );
};

export default ThemeCard;
