import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TextField,
  Grid,
  CardContent,
  InputAdornment,
} from '@mui/material';
import PaginationControls from 'component/base/PaginationControls';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { SearchRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useActivityLog } from 'api/siteMetrics';
import { ActivityLogPaginationState } from 'api/siteMetrics';
import { useGetSiteSettings, useSiteStartEventsLogging, useSiteStopEventsLogging } from 'api/site';
import { formatDate } from 'utils/dateFormat';
import { useTranslation } from 'react-i18next';
import sub from 'date-fns/sub';
import formatDistance from 'date-fns/formatDistanceToNow';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { loadDefaultPerPage } from 'utils/paginate';
import { Gravatar } from 'component/base/Gravatar';

const PREFIX = 'ActivityLog';

const classes = {
  tableCell: `${PREFIX}TableCell`,
  firstRow: `${PREFIX}FirstRow`,
  author: `${PREFIX}Author`,
};

const Root = styled('div')({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.firstRow}`]: {
    verticalAlign: 'top',
    fontWeight: 'normal !important' as 'normal',
  },
  [`& .${classes.author}`]: {
    fontWeight: 'normal',
    verticalAlign: 'top',
  },
});

function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

function UppercaseFirstLetter(str: string = '') {
  return `${str[0]?.toUpperCase() ?? ''}${str.slice(1)}`;
}

export default function InstalledThemeRowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}
export function ActivityLog({
  siteId,
  duration,
}: {
  readonly siteId: string;
  readonly duration: string;
}) {
  let dateFrom: Date | undefined;
  const dateTo = new Date();
  const durationNumber = Number(duration.slice(0, -1));
  const durationUnit = duration.slice(-1);
  switch (durationUnit) {
    case 'm':
      dateFrom = sub(dateTo, { minutes: durationNumber });
      break;
    case 'h':
      dateFrom = sub(dateTo, { hours: durationNumber });
      break;
    case 'd':
      dateFrom = sub(dateTo, { days: durationNumber });
      break;
  }

  const { data: siteSettingsData, isPending: siteSettingsIsLoading } = useGetSiteSettings(siteId);
  const { mutateAsync: startEventsLogging, isPending: startEventsLoggingLoading } =
    useSiteStartEventsLogging(siteId);
  const { mutateAsync: stopEventsLogging, isPending: stopEventsLoggingLoading } =
    useSiteStopEventsLogging(siteId);
  const [pagination, setPagination] = useState<ActivityLogPaginationState>({
    perPage: loadDefaultPerPage(),
    activePageNumber: 1,
    filter: '',
    siteId,
    dateFrom: dateFrom && dateFrom.toISOString(),
    dateTo: dateFrom && dateTo.toISOString(),
  });

  useEffect(() => {
    setPagination({
      ...pagination,
      dateFrom: dateFrom && dateFrom.toISOString(),
      dateTo: dateFrom && dateTo.toISOString(),
    });
  }, [duration]);

  const { data, status, refetch } = useActivityLog(pagination);
  let searchTimeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    refetch();
  }, [pagination.activePageNumber, pagination.perPage, pagination.filter, pagination.dateFrom]);

  const { t } = useTranslation();

  const activityLogItems = data?.data?.result || [];
  const metadata = data?.data.metadata;

  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (perPage: number) => {
    setPagination({
      ...pagination,
      activePageNumber: 1,
      perPage,
    });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setPagination({ ...pagination, activePageNumber: 1, filter: event.target.value });
    }, 300);
  };

  const renderTableRows = () => {
    if (status === 'pending') {
      return <InstalledThemeRowSkeleton />;
    }

    return activityLogItems.map(activityLogItem => {
      return (
        <TableRow key={String(activityLogItem.id)}>
          <TableCell className={classes.firstRow}>
            <>
              <Box>
                {t('time_ago', { time: formatDistance(new Date(activityLogItem.created_at)) })}
              </Box>
              {formatDate({
                date: activityLogItem.created_at,
                dateStyle: 'short',
                timeStyle: 'short',
              })}
            </>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box display="inline-block">
              <Gravatar name={activityLogItem.display_name} email={activityLogItem.user_email} />
            </Box>
            <Box display="inline-block" className={classes.author} paddingLeft={1}>
              <Box>
                {activityLogItem.display_name ? activityLogItem.display_name : t('unknown')}
              </Box>
              <Box>{UppercaseFirstLetter(activityLogItem.roles)}</Box>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>{activityLogItem.ip}</TableCell>
          <TableCell className={classes.tableCell}>
            {UppercaseFirstLetter(activityLogItem.type)}
          </TableCell>
          <TableCell className={classes.tableCell}>{htmlDecode(activityLogItem.label)}</TableCell>
          <TableCell className={classes.tableCell}>
            {UppercaseFirstLetter(activityLogItem.action)}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {htmlDecode(activityLogItem.description)}
          </TableCell>
        </TableRow>
      );
    });
  };

  const placeholder = t('search_authors_label');

  return (
    <Root>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} md="auto">
          <TextField
            onChange={handleFilter}
            variant="outlined"
            placeholder={placeholder}
            disabled={!siteSettingsData?.data.result.wp_activity_log}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disableTypography component="button">
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <ProgressiveButton
            onClick={async () => {
              if (siteSettingsData?.data.result.wp_activity_log) {
                await stopEventsLogging();
              } else {
                await startEventsLogging();
              }
              await refetch();
            }}
            isLoading={
              siteSettingsIsLoading || startEventsLoggingLoading || stopEventsLoggingLoading
            }
            text={siteSettingsData?.data.result.wp_activity_log ? t('disable') : t('enable')}
          />
        </Grid>
      </Grid>
      {siteSettingsData?.data.result.wp_activity_log ? (
        <CardContent>
          <TableContainer>
            <Table aria-label="Site Activity Logs">
              <TableHead>
                <TableRow>
                  <TableCell width={170}>{t('date')}</TableCell>
                  <TableCell width={170}>{t('author')}</TableCell>
                  <TableCell>{t('activity_log_table_ip')}</TableCell>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('label')}</TableCell>
                  <TableCell>{t('action')}</TableCell>
                  <TableCell>{t('description')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.filter !== '' && activityLogItems.length === 0 ? (
                  <NoResultsFound colSpan={7} />
                ) : (
                  renderTableRows()
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            totalRowCount={metadata?.total ?? activityLogItems.length}
            perPage={pagination.perPage}
            onPageChange={handlePaginationChange}
            onPerPageChange={handlePerPage}
          />
        </CardContent>
      ) : null}
    </Root>
  );
}
