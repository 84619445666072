import { FC, ReactElement } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

interface ContentViewCardProps {
  readonly children: ReactElement;
  readonly title?: ReactElement;
  readonly subheader?: ReactElement;
  readonly testId?: string;
}

const ContentViewCard: FC<ContentViewCardProps> = ({ children, title, subheader, testId }) => (
  <Card data-testid={testId}>
    {title ? <CardHeader title={title} subheader={subheader ?? undefined} /> : null}
    <CardContent>{children}</CardContent>
  </Card>
);

export default ContentViewCard;
