import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const PREFIX = 'NoPluginsInstalled';

const classes = {
  box: `${PREFIX}Box`,
};

const StyledBox = styled(Box)({
  [`&.${classes.box}`]: {
    padding: '1.25rem 0',
    textAlign: 'center',
    '@media (min-width: 60rem)': {
      padding: '2.5rem 0',
    },
    '& h3': {
      fontWeight: 600,
      marginBottom: '0.3125rem',
    },
    '& button': {
      marginTop: '1rem',
    },
  },
});

export default function NoResultsFound() {
  const { t } = useTranslation();

  return (
    <StyledBox className={classes.box}>
      <Typography variant="h3">{t('no_plugins_installed_title')}</Typography>
      <Typography>{t('no_plugins_installed_description')}</Typography>
      <Button variant="contained" color="primary">
        {t('add_new_entity', {
          entity: t('plugin'),
        })}
      </Button>
    </StyledBox>
  );
}
