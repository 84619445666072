export const noData = {
  '&': {
    display: 'flex',
    flexDirection: 'column',
    height: '21.875rem',
    margin: 'auto',
    padding: '1.25rem 0',
    textAlign: 'center',
    '@media (min-width: 60rem)': {
      padding: '2.5rem 0',
    },
    '& svg': {
      display: 'block',
      margin: '0 auto',
      marginBottom: '1.25rem',
    },
    '& h3': {
      fontWeight: 600,
      marginBottom: '0.3125rem',
    },
    '& p': {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '31.25rem',
    },
    '& > *': {
      '&:first-of-type': {
        marginTop: 'auto',
      },
      '&:last-child': {
        marginBottom: 'auto',
      },
    },
  },
};
