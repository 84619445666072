import { InputAdornment } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FtpAccount, UpdateFtpAccountData, useUpdateFtpAccount } from 'api/ftpAccounts';
import { useParams } from 'react-router-dom';
import { TextField } from 'component/base/TextField';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useSnackbar } from 'component/hooks/useSnackbar';
import passwordStrength from 'password-strength-calc';

interface Props {
  readonly domain: string;
  readonly ftpAccount: FtpAccount;
  readonly onClose: () => unknown;
  readonly refetchFtpAccounts: () => Promise<unknown>;
}

export function UpdateFtpAccount({ ftpAccount, onClose, refetchFtpAccounts }: Props) {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const updateFtpAccount = useUpdateFtpAccount({
    id: siteId,
    username: ftpAccount.user ?? '',
  });
  const existingRelDir = ftpAccount.reldir?.replace('public_html/', '').replace('public_html', '');
  const { t } = useTranslation();

  const methods = useForm<
    UpdateFtpAccountData & {
      username: string;
    }
  >({
    defaultValues: {
      username: ftpAccount.user,
      new_password: '',
      homedir: existingRelDir,
    },
  });

  const { handleSubmit, reset } = methods;
  const onSubmit = async (data: UpdateFtpAccountData) => {
    const changedValues: { new_password?: string; homedir?: string } = {};

    if (data.new_password) {
      changedValues.new_password = data.new_password;

      if (passwordStrength(changedValues.new_password) < 60) {
        enqueueSnackbar(t('ftp_account_password_error'), {
          key: 'passwordStrengthError',
          variant: 'error',
        });
        return;
      }
    }
    if (data.homedir !== existingRelDir) {
      changedValues.homedir = data.homedir ? `public_html/${data.homedir}` : 'public_html';
    }

    await updateFtpAccount.mutateAsync(changedValues);
    await refetchFtpAccounts();
    reset();
    onClose();
  };

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <ConfirmationDialog
      action="confirm"
      description={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField fullWidth name="username" label="Username" disabled />
            <TextField
              defaultValue=""
              name="new_password"
              disabled={updateFtpAccount.isPending}
              fullWidth
              label="Password"
              type="password"
            />
            <TextField
              defaultValue=""
              name="homedir"
              disabled={updateFtpAccount.isPending}
              fullWidth
              label="Directory"
              startAdornment={
                <InputAdornment position="start" disableTypography>
                  {'public_html/'}
                </InputAdornment>
              }
            />
          </form>
        </FormProvider>
      }
      id="updateFtpAccountModal"
      open
      onConfirm={handleSubmit(onSubmit)}
      onClose={handleClose}
      title={t('update_ftp_account')}
      confirmText={t('update')}
    />
  );
}
