import { FC, useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Tab,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import debounce from 'debounce';
import { Search } from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';

import { NewPlugin, Plugin, useGetPlugins, usePluginList } from 'api/plugins';
import PluginCard from './PluginCard';
import LoadingContainer from 'component/partial/LoadingContainer';
import { useTranslation } from 'react-i18next';
import { loadingContainer } from 'theme/custom/loading-container';
import PaginationControls from 'component/base/PaginationControls';

const PREFIX = 'AddNewPlugin';

const classes = {
  loadingContainer: `${PREFIX}LoadingContainer`,
  search: `${PREFIX}Search`,
};

const StyledCard = styled(Card)({
  [`& .${classes.loadingContainer}`]: {
    ...loadingContainer,
  },
  [`& .${classes.search}`]: {
    '@media (min-width: 37.5rem)': {
      maxWidth: '18.75rem',
    },
  },
});

interface AddNewPluginProps {
  readonly siteId: string;
}

export const AddNewPlugin: FC<AddNewPluginProps> = ({ siteId }) => {
  const [tabValue, setTabValue] = useState<string>('all');
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(12);
  const [browse, setBrowse] = useState<string>('');
  const [installingPlugin, setInstallingPlugin] = useState('');
  const { t } = useTranslation();
  const searchRef = useRef(null);

  const handleTabChange = (value: string, browse?: string) => {
    setBrowse(browse || '');
    setTabValue(value);
    setInstallingPlugin('');
    setActivePageNumber(1);

    if (!browse) {
      setBrowse('');
    }
  };
  const { data: installedPlugins, isFetching: isInstalledPluginFetching } = usePluginList(siteId);
  const { isLoading, data, refetch, isFetching } = useGetPlugins({
    siteId,
    page: activePageNumber,
    perPage: perPage,
    ...(browse !== '' ? { query: browse } : tabValue !== 'all' && { query: tabValue }),
  });

  const isInstalled = (name: string) => {
    const themesArr = installedPlugins?.data.result || [];
    return themesArr.find((plugin: Plugin) => plugin.name === name) !== undefined ?? false;
  };

  useEffect(() => {
    refetch();
  }, [tabValue, perPage, activePageNumber]);

  useEffect(() => {
    if (browse.length >= 3) {
      refetch();
    }
  }, [browse]);

  const pluginMetaData = data?.data?.metadata || null;
  const pluginList = data?.data?.result || [];

  const handlePaginationChange = (newPage: number) => {
    setActivePageNumber(newPage);
    refetch();
  };

  const handlePerPage = (perPage: number) => {
    setPerPage(perPage);
  };

  return (
    <StyledCard data-testid="availablePlugins">
      <CardHeader title={t('plugin_title')} />
      <CardContent>
        <Box className={classes.search}>
          <TextField
            inputRef={searchRef}
            disabled={isLoading || isFetching}
            placeholder={t('plugin_search_placeholder')}
            variant="outlined"
            fullWidth
            onChange={debounce((event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length >= 3 || event.target.value === '') {
                setBrowse(event.target.value);
                setTabValue('all');
              }
            }, 700)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disableTypography component="button">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TabContext value={tabValue}>
          <TabList
            variant="scrollable"
            onChange={(_event: React.ChangeEvent<any>, value: string) => handleTabChange(value)}
          >
            <Tab label={t('plugin_tab_all')} value="all" />
            <Tab label={t('plugin_tab_caching')} value="caching" />
            <Tab label={t('plugin_tab_optimization')} value="optimization" />
            <Tab label={t('plugin_tab_ecommerce')} value="ecommerce" />
          </TabList>
        </TabContext>
        <Grid container spacing={4} className={classes.loadingContainer}>
          {isLoading || isFetching ? <LoadingContainer /> : null}
          {installedPlugins && !(isLoading || isFetching) && pluginList.length === 0 ? (
            <Grid item xs={12}>
              <Typography align="center">
                <strong>{t('no_results_found')}</strong>
              </Typography>
            </Grid>
          ) : null}
          {pluginList.map((plugin: NewPlugin, index: number) => (
            <PluginCard
              isSyncing={isInstalledPluginFetching}
              isInstalled={isInstalled(plugin.slug)}
              installingPlugin={installingPlugin}
              setInstallName={setInstallingPlugin}
              key={`${plugin.slug}-${index}`}
              {...plugin}
            />
          ))}
        </Grid>
        {pluginMetaData ? (
          <PaginationControls
            totalRowCount={pluginMetaData.total}
            perPage={perPage}
            onPageChange={handlePaginationChange}
            onPerPageChange={handlePerPage}
            perPageOptions={[12, 48, 96]}
          />
        ) : null}
      </CardContent>
    </StyledCard>
  );
};
