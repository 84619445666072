import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography, Grid } from '@mui/material';
import { CreateUserSchema } from 'api/security';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { TextField } from 'component/base/TextField';
import { useAddSiteProtectionUser } from 'api/security';

interface CreateUserProps {
  readonly open: boolean;
  readonly siteId: string;
  readonly onClose: () => void;
}

export const CreateUser: FC<CreateUserProps> = ({ open, onClose, siteId }) => {
  const { mutateAsync, isPending } = useAddSiteProtectionUser({ siteId });
  const { t } = useTranslation();
  const methods = useForm<CreateUserSchema>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (data: CreateUserSchema) => {
    await mutateAsync(data);
    methods.reset();
    onClose();
  };

  const { handleSubmit } = methods;

  const modalBody = () => (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography>{t('security_create_user_description')}</Typography>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField name="username" label={t('username')} fullWidth color="primary" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                label={t('password')}
                fullWidth
                type="password"
                color="primary"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );

  return (
    <ConfirmationDialog
      forceLoadingState={isPending}
      onConfirm={handleSubmit(onSubmit)}
      confirmDisabled={!methods.formState.isValid}
      action="confirm"
      onClose={handleClose}
      open={open}
      description={modalBody()}
      title={t('security_create_user')}
    />
  );
};
