import { Card, CardContent, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const PREFIX = 'NoSiteDetails';

const classes = {
  container: `${PREFIX}Container`,
};

const StyledContainer = styled(Container)({
  [`&.${classes.container}`]: {
    minHeight: '100vh',
    display: 'flex',
    [`& > *`]: {
      margin: 'auto !important',
      minHeight: 'auto !important',
    },
  },
});

export function NoSiteDetails() {
  const { t } = useTranslation();

  return (
    <StyledContainer maxWidth="sm" className={classes.container}>
      <Card>
        <CardContent>
          <Typography align="center">{t('site_details_not_found')}</Typography>
        </CardContent>
      </Card>
    </StyledContainer>
  );
}
