import { useState, useEffect } from 'react';
import paginate from 'utils/paginate';
import PaginationControls from 'component/base/PaginationControls';
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AutomatedBackup,
  useGetAutomatedBackups,
  useGetBackupInProgressTasks,
  usePollTask,
} from 'api/backups';
import { sortByKey } from 'utils/sort';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import ContentViewCard from 'component/base/ContentViewCard';
import { RestoreRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { formatDate } from 'utils/dateFormat';
import RestoreAutomatedBackup from './RestoreAutomatedBackup';
import { loadDefaultPerPage } from 'utils/paginate';

interface PaginationState {
  perPage: number;
  activePageNumber: number;
  filter: string;
}

type Props = {
  readonly siteId: string;
};

export const AutomatedBackups = ({ siteId }: Props) => {
  const { data, isLoading, refetch } = useGetAutomatedBackups(siteId);
  const { enqueueSnackbar } = useSnackbar();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [restoreId, setRestoreId] = useState<string | null>(null);
  const { data: pollTaskData } = usePollTask(
    siteId,
    taskId ?? '',
    taskId !== null && restoreId !== null
  );
  const { data: getBackupTasksInProgress, refetch: backupInProgressTasksRefetch } =
    useGetBackupInProgressTasks(siteId);
  const backupTask = pollTaskData?.data.result?.find(task => task.id === taskId);
  const raw = data?.data?.result || [];

  useEffect(() => {
    const automatedBackupTasksInProgress = getBackupTasksInProgress?.data.result?.find(
      backupTask => {
        return (
          backupTask.message &&
          backupTask.task_type === 'automated_restore' &&
          backupTask.site_id?.toString() === siteId
        );
      }
    );

    if (automatedBackupTasksInProgress) {
      setRestoreId(automatedBackupTasksInProgress.message || null);
      setTaskId(automatedBackupTasksInProgress.id || null);
    }
  }, [getBackupTasksInProgress]);

  const { t } = useTranslation();
  const [pagination, setPagination] = useState<PaginationState>({
    perPage: loadDefaultPerPage(),
    activePageNumber: 1,
    filter: '',
  });
  const filteredBackups =
    pagination.filter.length > 0
      ? raw.filter(b => b.created_at?.toLowerCase().indexOf(pagination.filter.toLowerCase()) !== -1)
      : raw;
  const backupList = paginate(filteredBackups, pagination.perPage, pagination.activePageNumber);
  const orderedBackups = sortByKey(backupList, 'created_at', true);
  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (perPage: number) => {
    setPagination({ ...pagination, activePageNumber: 1, perPage });
  };

  useEffect(() => {
    if (backupTask && backupTask.task_status === 'DONE') {
      refetch();
      setTaskId(null);
      setRestoreId(null);

      enqueueSnackbar(t('backup_restored_successfully'), {
        key: 'restoreAutomatedBackupSuccess',
        variant: 'success',
      });
    }
  }, [backupTask]);

  const backupStatus = (backup: AutomatedBackup) => {
    if (!backupTask || backup.recoveryPoint !== backupTask.message) {
      return 'Available';
    }

    const status = backupTask.task_status;

    switch (status) {
      case 'PROGRESS':
        return 'Restoring';
      case 'NEW':
        return 'Creating';
      case 'ERROR':
        return 'Error';
      default:
        return 'Available';
    }
  };

  return (
    <>
      <ContentViewCard title={<Typography variant="h2">{t('automated_backups')}</Typography>}>
        <>
          <TableContainer>
            <Table aria-label={'backups table'}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('status')}</TableCell>
                  <TableCell>{t('created')}</TableCell>
                  <TableCell>{t('actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  Array.from({ length: 3 }).map((v, i) => (
                    <TableRow key={`skeleton-${i}`}>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="text" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : pagination.filter !== '' && filteredBackups.length === 0 ? (
                  <NoResultsFound colSpan={4} />
                ) : (
                  orderedBackups.map(backup => (
                    <TableRow key={backup.created_at?.toString()}>
                      <TableCell>{t('platform_backup')}</TableCell>
                      <TableCell>{backupStatus(backup)}</TableCell>
                      <TableCell>
                        {formatDate({
                          date: new Date(backup.created_at!).toISOString(),
                        })}
                      </TableCell>
                      <TableCell width="10%">
                        <Grid
                          container
                          justifyContent="space-between"
                          direction="row"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                t('backup_tooltip', { type: t('backup_label_restore') }) as string
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  setRestoreId(String(backup.recoveryPoint) || null);
                                }}
                                disabled={
                                  taskId !== null &&
                                  restoreId !== null &&
                                  backupTask?.task_status !== 'DONE'
                                }
                                size="large"
                              >
                                <RestoreRounded />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!isLoading && (
            <PaginationControls
              totalRowCount={filteredBackups.length}
              perPage={pagination.perPage}
              onPageChange={handlePaginationChange}
              onPerPageChange={handlePerPage}
            />
          )}
        </>
      </ContentViewCard>
      <RestoreAutomatedBackup
        onRestore={taskId => {
          setTaskId(taskId);
          backupInProgressTasksRefetch();
        }}
        onClose={() => setRestoreId(null)}
        siteId={siteId}
        restoreId={restoreId}
        taskId={taskId}
      />
    </>
  );
};
