import { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  LinearProgress,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import { formatNumberReporting } from 'utils/number';
import { SourceZone } from 'api/siteMetrics';
import { useTranslation } from 'react-i18next';

const PREFIX = 'SourceTable';

const classes = {
  tableCell: `${PREFIX}TableCell`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.tableCell}`]: {
    '& > span': {
      alignItems: 'center',
      textTransform: 'none',
      '& > span': {
        fontSize: '0.875rem',
        fontWeight: 400,
        marginLeft: '0.3125rem',
        opacity: '0.5',
      },
    },
  },
});

interface SourceTableProps {
  readonly data: SourceZone[];
  readonly label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  readonly id: string;
  readonly total: number;
  readonly formatCountry?: any;
  readonly fullWidth?: boolean;
}

const SourceTable: FC<SourceTableProps> = ({ data, label, total, formatCountry, fullWidth }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowAll(false);
  }, [total]);

  const bodyContent = data ?? [];
  const formattedArr = showAll ? bodyContent : bodyContent.slice(0, 6);
  const content = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <Typography variant="h2" component="span">
                {label} <Box component="span"> | {bodyContent.length}</Box>
              </Typography>
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right" width={116}>
              {bodyContent.length > 5 ? (
                <Button color="primary" onClick={() => setShowAll(!showAll)}>
                  {t('source_table_button', {
                    type: showAll ? 'Top' : 'All',
                  })}
                </Button>
              ) : null}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedArr.map((source: SourceZone, i) => {
            if (!source.dimensions.metric) {
              return null;
            }
            return (
              <TableRow key={source.dimensions.metric + i}>
                <TableCell
                  sx={{
                    maxWidth: '100px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Tooltip
                    title={
                      formatCountry
                        ? formatCountry(source.dimensions.metric)
                        : source.dimensions.metric
                    }
                  >
                    {formatCountry
                      ? formatCountry(source.dimensions.metric)
                      : source.dimensions.metric}
                  </Tooltip>
                </TableCell>
                <TableCell align="right">{formatNumberReporting(source.count, 2)}</TableCell>
                <TableCell>
                  <LinearProgress variant="determinate" value={(source.count / total) * 100} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (fullWidth) {
    return (
      <StyledGrid item xs={12}>
        {content}
      </StyledGrid>
    );
  }

  return (
    <StyledGrid item xs={12} md={6}>
      {content}
    </StyledGrid>
  );
};

export default SourceTable;
