import { forwardRef, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { Dialog, Slide, Typography, Button, Box, Grid, Link } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Logo from 'component/svg/logo.svg?react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactTerminal, TerminalContextProvider } from 'react-terminal';
import { useCliCommand } from 'api/cli';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { getIsWpSiteControl } from 'utils';
import { isInIframe } from '../../../utils/iframe';

const PREFIX = 'WPCli';

const classes = {
  dialog: `${PREFIX}Dialog`,
  header: `${PREFIX}Header`,
  body: `${PREFIX}Body`,
  title: `${PREFIX}Title`,
  subtitle: `${PREFIX}Subtitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    margin: '0',
    '& .MuiDialog-paperFullScreen': {
      overflow: 'hidden',
    },
    '& .MuiDialog-paper': {
      margin: '0',
    },
  },
  [`& .${classes.header}`]: {
    flex: '0 0 auto',
    padding: '1.25rem 1rem',
    '& .MuiGrid-item:first-of-type': {
      display: 'none',
      '@media (min-width: 37.5rem)': {
        display: 'block',
      },
    },
    '& .MuiGrid-item:last-child': {
      minWidth: '0',
      flexShrink: '0',
    },
    '& svg': {
      display: 'block',
    },
  },
  [`& .${classes.body}`]: {
    flex: '1 1 auto',
    padding: '0 1rem 1.25rem 1rem',
    overflowY: 'scroll',
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { readonly children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  readonly siteId: string;
};

export const WPCli = ({ siteId }: Props) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const { mutateAsync: cliCommand } = useCliCommand(siteId);
  const isWpSiteControl = getIsWpSiteControl();
  const isIframe = isInIframe();

  let wpCliModalDescription = 'wp_cli_modal_description';
  if (isIframe) {
    wpCliModalDescription = 'wp_cli_iframe_modal_description';
  } else if (isWpSiteControl) {
    wpCliModalDescription = 'wp_cli_site_control_modal_description';
  }

  const commands = {
    wp: async (command: string) => {
      const splitCommand = command.split(' ');
      const allParams = splitCommand.reduce<string>((prev, curr) => {
        if (curr.startsWith('--')) {
          return prev.length ? `${prev} ${curr}` : curr;
        }
        return prev;
      }, '');
      const allValues = splitCommand.slice(2).reduce<string>((prev, curr) => {
        if (!curr.startsWith('--')) {
          return prev.length ? `${prev} ${curr}` : curr;
        }
        return prev;
      }, '');

      const data = await cliCommand({
        command: splitCommand[0],
        action: splitCommand[1],
        value: allValues ?? undefined,
        params: allParams ?? undefined,
      });

      try {
        const parsedResponse = JSON.parse(data.data.result.response);

        if (parsedResponse.errors) {
          return parsedResponse.errors;
        }

        return <div style={{ whiteSpace: 'pre-wrap' }}>{parsedResponse.data}</div>;
      } catch (e) {
        return 'there was an error with the command provided, please use `wp help` for a list of all available commands.';
      }
    },
  };

  return (
    <>
      <StyledDialog
        fullScreen
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        className={classes.dialog}
      >
        <div className={classes.header}>
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            {!sdk && !isWpSiteControl && (
              <Grid item>
                <Logo />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6">
                {t('wp_cli_title')}
                <Box>
                  <Typography variant="body2">
                    <Trans
                      i18nKey={wpCliModalDescription}
                      components={[
                        <Link key="supportLink" href="https://support.rocket.net" />,
                        <Link
                          key="developerLink"
                          href="https://developer.wordpress.org/cli/commands/"
                        />,
                      ]}
                    />
                  </Typography>
                  {!!sdk?.wpCliCustomDescription && (
                    <Typography>{sdk.wpCliCustomDescription}</Typography>
                  )}
                </Box>
              </Typography>
            </Grid>
            <Grid item marginLeft="auto" alignSelf="flex-start">
              <Button variant="outlined" color="primary" onClick={() => setOpenModal(false)}>
                {t('close')}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Box className={classes.body}>
          <TerminalContextProvider>
            <ReactTerminal
              themes={{
                main: {
                  themeBGColor: '#000',
                  themeToolbarColor: '#000',
                  themeColor: '#fff',
                  themePromptColor: '#a917a8',
                },
              }}
              theme="main"
              showControlBar={false}
              commands={commands}
              welcomeMessage={<div>{'Type `wp help` for a full list of commands'}</div>}
            />
          </TerminalContextProvider>
        </Box>
      </StyledDialog>
      <ProgressiveButton
        variant="outlined"
        text="WP-CLI"
        isLoading={false}
        endIcon={<FontAwesomeIcon icon={faLongArrowRight} />}
        onClick={() => {
          setOpenModal(true);
        }}
      />
    </>
  );
};
