import SSHKeys from './components/SSH/SSHKeys';
import SettingsCard from './components/SiteSettings/SettingsCard';
import DeleteSiteSupport from './components/DeleteSiteSupport';
import { SiteDetail } from 'api/site';

export const Advanced = ({
  siteDetails,
  isWpSiteControl,
}: {
  readonly siteDetails?: SiteDetail;
  readonly isWpSiteControl: boolean;
}) => {
  return (
    <>
      <SettingsCard siteDetails={siteDetails} />
      <SSHKeys />
      <DeleteSiteSupport siteDetails={siteDetails} isWpSiteControl={isWpSiteControl} />
    </>
  );
};
