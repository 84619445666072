import { Box, ListItem, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import PHPVersion from 'component/svg/settings/php-version.svg?react';
import DBManagement from 'component/svg/settings/phpmyadmin.svg?react';
import PluginUpdate from 'component/svg/settings/plugin-update.svg?react';
import SSHAccess from 'component/svg/settings/ssh-access.svg?react';
import WPCache from 'component/svg/settings/wpcore-update.svg?react';
import Themes from 'component/svg/settings/theme-update.svg?react';
import WPCli from 'component/svg/settings/wp-cli.svg?react';
import { settingsItem, settingsGrid, settingsIcon } from 'theme/custom/settings';
import { useTranslation } from 'react-i18next';

const PREFIX = 'SettingsCardSkeleton';

const classes = {
  settingsItem: `${PREFIX}SettingsItem`,
  settingsGrid: `${PREFIX}SettingsGrid`,
  settingsIcon: `${PREFIX}SettingsIcon`,
};

const Root = styled('div')({
  [`& .${classes.settingsItem}`]: {
    ...settingsItem,
  },
  [`& .${classes.settingsGrid}`]: {
    ...settingsGrid,
  },
  [`& .${classes.settingsIcon}`]: {
    ...settingsIcon,
  },
});

const icon: { [key: string]: React.ReactNode | undefined } = {
  database_management: <DBManagement />,
  current_php_version: <PHPVersion />,
  wp_core_auto_updates: <WPCache />,
  wp_theme_auto_updates: <Themes />,
  wp_plugin_auto_updates: <PluginUpdate />,
  ssh_access: <SSHAccess />,
  wp_cli: <WPCli />,
};
const rows = [
  'database_management',
  'wp_cli',
  'current_php_version',
  'ssh_access',
  'wp_core_auto_updates',
  'wp_plugin_auto_updates',
  'wp_theme_auto_updates',
];

export const SettingsCardSkeleton = () => {
  const { t } = useTranslation();

  return (
    <Root>
      {rows.map(row => {
        return (
          <ListItem key={row} className={classes.settingsItem}>
            <Box className={classes.settingsIcon}>{icon[row]}</Box>
            <Grid container spacing={2} className={classes.settingsGrid}>
              <Grid item xs={12} md={8}>
                <Typography variant="h3">{t(`${row}_title`)}</Typography>
                <Typography>{t(`${row}_description`)}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Skeleton />
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </Root>
  );
};
