import {
  useSiteFiles,
  SiteFile,
  useCreateSiteFolder,
  useUploadSiteFile,
  useDeleteSiteFile,
  useDownloadSiteFile,
  SiteDetail,
  CreateFolderRequest,
  UploadFileRequest,
} from 'api/site';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CardHeader, // Checkbox,
  Box,
  Button,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  Link,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ArrowDropUp, ArrowDropDown, Edit } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, FC, useRef, useEffect, ReactNode } from 'react';
import paginate from 'utils/paginate';
import PaginationControls from 'component/base/PaginationControls';
import { Skeleton } from '@mui/material';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faFolder,
  faEllipsisV,
  faArrowToBottom,
  // faUser,
  // faEdit,
  faLink,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { pagination } from 'theme/custom/pagination';
import { Search } from '@mui/icons-material';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { FormProvider, useForm } from 'react-hook-form';
import { TextField } from 'component/base/TextField';
import { useQueryParams } from 'component/hooks/queryParams';
import { useDropzone } from 'react-dropzone';
import { loadDefaultPerPage } from 'utils/paginate';
import { useSnackbar } from 'component/hooks/useSnackbar';
import copy from 'copy-to-clipboard';

const PREFIX = 'FileManager';

const classes = {
  pagination: `${PREFIX}Pagination`,
  button: `${PREFIX}Button`,
  search: `${PREFIX}Search`,
};

const Root = styled('div')({
  [`& .${classes.pagination}`]: {
    ...pagination,
  },
  [`& .${classes.button}`]: {
    minHeight: '2.75rem',
  },
  [`& .${classes.search}`]: {
    '@media (min-width: 37.5rem)': {
      maxWidth: '18.75rem',
    },
  },
});

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        {/* <TableCell>
          <Skeleton />
        </TableCell> */}
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton width={36} />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

interface RowProps extends SiteFile {
  readonly siteId: string;
  readonly domain: string;
}

const FileRow: FC<RowProps> = ({
  domain,
  file,
  type,
  humansize,
  nicemode,
  mtime,
  path,
  siteId,
}) => {
  const { t } = useTranslation();
  const [openRowMenu, setOpenRowMenu] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<'delete' | null>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const deleteSiteFile = useDeleteSiteFile(siteId);

  const relativePath = path?.split('public_html')[1] ?? '';
  const downloadSiteFile = useDownloadSiteFile(siteId, file, relativePath);
  const fileLink = `https://${domain}${relativePath}/${file}`;

  const onDialogConfirm = async () => {
    if (showDialog === 'delete') {
      await deleteSiteFile.mutateAsync({
        file: `${relativePath}/${file}`,
      });
    }
    setShowDialog(null);
    return;
  };

  const renderDialogTitle = () => {
    if (showDialog === 'delete') {
      return type === 'dir' ? t('delete_folder') : t('delete_file');
    }
    return null;
  };

  const renderDialogDescription = () => {
    if (showDialog === 'delete') {
      return type === 'dir'
        ? t('delete_folder_description', { fileName: file })
        : t('delete_file_description', { fileName: file });
    }
    return null;
  };

  return (
    <>
      {showDialog ? (
        <ConfirmationDialog
          action={showDialog === 'delete' ? 'delete' : 'confirm'}
          onClose={() => setShowDialog(null)}
          open={Boolean(showDialog)}
          onConfirm={onDialogConfirm}
          title={renderDialogTitle()}
          description={renderDialogDescription()}
        />
      ) : null}
      <TableRow
        onClick={() => {
          if (type === 'dir') {
            navigate(`/sites/${siteId}/file-manager?path=${path?.split('public_html')[1]}/${file}`);
          } else {
            navigate(
              `/sites/${siteId}/file-manager/view?filename=${file}&directory=${relativePath}`
            );
          }
        }}
      >
        {/* <TableCell>
          <Checkbox checked={false} onChange={() => {}} />
        </TableCell> */}
        <TableCell>
          <Link>
            {type === 'dir' ? (
              <FontAwesomeIcon icon={faFolder} />
            ) : (
              <FontAwesomeIcon icon={faFile} />
            )}
          </Link>
          {file}
        </TableCell>
        <TableCell>{humansize}</TableCell>
        <TableCell>{format(new Date(mtime! * 1000), 'yyyy-MM-dd h:m a')}</TableCell>
        <TableCell>{nicemode}</TableCell>
        <TableCell>
          <Button
            ref={anchorRef}
            variant="text"
            color="primary"
            onClick={async e => {
              setOpenRowMenu(true);
              e.preventDefault();
              e.stopPropagation();
            }}
            fullWidth
          >
            <FontAwesomeIcon icon={faEllipsisV} size="lg" />
          </Button>
          <Popper
            open={openRowMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 100 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpenRowMenu(false)}>
                    <MenuList autoFocusItem={openRowMenu} id="menu-list-grow" onKeyDown={() => {}}>
                      {type !== 'dir' && (
                        <MenuItem
                          onClick={e => {
                            navigate(
                              `/sites/${siteId}/file-manager/view?filename=${file}&directory=${relativePath}`
                            );
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Box display="inline-block" marginRight="12px">
                            <Edit />{' '}
                          </Box>{' '}
                          {t('view_edit')}
                        </MenuItem>
                      )}
                      {type !== 'dir' && (
                        <MenuItem
                          onClick={async e => {
                            e.preventDefault();
                            e.stopPropagation();
                            const response = await downloadSiteFile();
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', file ?? '');
                            document.body.appendChild(link);
                            link.click();
                          }}
                        >
                          <Box display="inline-block" marginRight={2}>
                            <FontAwesomeIcon icon={faArrowToBottom} size="lg" />
                          </Box>{' '}
                          {t('download')}
                        </MenuItem>
                      )}
                      {/* <MenuItem onClick={() => {}}>
                        <Box display="inline-block" marginRight={2}>
                          <FontAwesomeIcon icon={faEdit} size="sm" />
                        </Box>{' '}
                        {t('rename')}
                      </MenuItem> */}
                      {/* <MenuItem onClick={() => {}}>
                        <Box display="inline-block" marginRight={2}>
                          <FontAwesomeIcon icon={faUser} size="sm" />
                        </Box>{' '}
                        {t('change_permission')}
                      </MenuItem> */}
                      <MenuItem
                        onClick={e => {
                          copy(fileLink);
                          setOpenRowMenu(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Box display="inline-block" marginRight={2}>
                          <FontAwesomeIcon icon={faLink} size="sm" />
                        </Box>{' '}
                        {t('copy_link')}
                      </MenuItem>
                      <MenuItem
                        onClick={e => {
                          setShowDialog('delete');
                          setOpenRowMenu(false);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Box display="inline-block" marginRight={2}>
                          <FontAwesomeIcon icon={faTrash} size="sm" />
                        </Box>{' '}
                        {t('delete')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </TableCell>
      </TableRow>
    </>
  );
};

function CreateSiteFolderModal({
  siteId,
  onClose,
  path,
}: {
  readonly siteId: string;
  readonly onClose: () => unknown;
  readonly path?: string;
}) {
  const { t } = useTranslation();
  const methods = useForm<CreateFolderRequest>({
    defaultValues: {
      name: '',
      path: path?.replace('public_html/', ''),
    },
  });
  const { handleSubmit, reset, getValues } = methods;
  const createSiteFolder = useCreateSiteFolder(siteId);

  const handleModalSubmit = async () => {
    await createSiteFolder.mutateAsync(getValues());
    onClose();
    reset();
  };

  return (
    <ConfirmationDialog
      action="confirm"
      title={t('create_new_type', { type: t('folder') })}
      description={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleModalSubmit)}>
            <TextField
              name="name"
              disabled={createSiteFolder.isPending}
              fullWidth
              label={t('name')}
            />
            {/* <TextField
            name="path"
            disabled={createSiteFolder.isPending}
            fullWidth
            label={t('path')}
          /> */}
          </form>
        </FormProvider>
      }
      onClose={onClose}
      onConfirm={handleSubmit(handleModalSubmit)}
    />
  );
}

function CreateSiteFileModal({
  siteId,
  onClose,
  path,
}: {
  readonly siteId: string;
  readonly onClose: () => unknown;
  readonly path?: string;
}) {
  const { t } = useTranslation();
  const methods = useForm<UploadFileRequest>({
    defaultValues: {
      filename: '',
      path: path?.replace('public_html/', ''),
      content: '// Write file here',
    },
  });
  const { handleSubmit, reset, getValues } = methods;
  const createSiteFile = useUploadSiteFile(siteId);

  const handleModalSubmit = async () => {
    await createSiteFile.mutateAsync(getValues());
    onClose();
    reset();
  };

  return (
    <ConfirmationDialog
      action="confirm"
      title={t('create_new_type', { type: t('file') })}
      description={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleModalSubmit)}>
            <TextField
              name="filename"
              disabled={createSiteFile.isPending}
              fullWidth
              label={t('name')}
            />
            {/* <TextField
            name="path"
            disabled={createSiteFile.isPending}
            fullWidth
            label={t('path')}
          /> */}
          </form>
        </FormProvider>
      }
      onClose={onClose}
      onConfirm={handleSubmit(handleModalSubmit)}
    />
  );
}

export const FileManager = ({ siteDetails }: { readonly siteDetails?: SiteDetail }) => {
  const { t } = useTranslation();

  const params = useQueryParams();
  const { siteId = '' } = useParams<{ siteId: string }>();
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [invertList, setInvertList] = useState(false);
  const isStaging = siteDetails?.production ? siteDetails.production !== Number(siteId) : false;
  const path = params.get('path') ?? (isStaging ? `/${siteDetails?.domain ?? ''}` : '');
  const { data, status, refetch } = useSiteFiles(siteId, path);
  const [perPage, setPerPage] = useState(loadDefaultPerPage());
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [filter, setFilter] = useState<string>('');
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [modalType, setModalType] = useState<'createFolder' | 'createFile' | null>(null);
  const navigate = useNavigate();
  const createSiteFile = useUploadSiteFile(siteId);
  const fullPath = path ? `public_html${path}` : 'public_html';
  const splitPath = fullPath.split('/');
  const { enqueueSnackbar } = useSnackbar();

  const {
    getRootProps,
    getInputProps,
    open: fileOpen,
  } = useDropzone({
    onDrop: async (acceptedFiles, fileRejections) => {
      const file: File | undefined = acceptedFiles[0];

      if (fileRejections.length) {
        enqueueSnackbar(t('invalid_file_type'), {
          key: 'invalidFileType',
          variant: 'error',
        });
      }

      if (file) {
        const content = await file.text();

        if (typeof content === 'string') {
          await createSiteFile.mutateAsync({
            filename: file.name,
            path: path ?? '',
            content,
          });
        }
      }
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      'application/epub+zip': ['.epub'],
      'application/json': ['.json'],
      'application/ld+json': ['.jsonld'],
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/rtf': ['.rtf'],
      'application/vnd.amazon.ebook': ['.azw'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.oasis.opendocument.presentation': ['.odp'],
      'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/xhtml+xml': ['.xhtml'],
      'application/xml': ['.xml'],
      'application/zip': ['.zip'],
      'audio/*': [],
      'font/otf': ['.otf'],
      'font/ttf': ['.ttf'],
      'font/woff': ['.woff'],
      'font/woff2': ['.woff2'],
      'image/*': [],
      'text/*': [],
      'video/*': [],
    },
  });

  useEffect(() => {
    // row clicked
    if (path || path === undefined) {
      refetch();
    }
  }, [path]);

  const handlePerPage = (value: number) => {
    setPerPage(value);
  };

  const handlePaginationChange = (newPage: number) => {
    setActivePageNumber(newPage);
    refetch();
  };

  const fuzzySearch = (query: string) => {
    const search = query.split(' ');
    const ret = (data?.data.result?.files ?? []).reduce<SiteFile[]>((found, i) => {
      let matches = 0;
      search.forEach((s: string) => {
        let props = 0;
        const match = s.toLowerCase();
        const keys = Object.entries(i).reduce((acc: string[], curr) => {
          return curr && typeof curr[1] === 'string' ? [...acc, curr[0]] : acc;
        }, []);

        keys.forEach(k => {
          const val = String(i[k as keyof SiteFile]);
          if (val.indexOf(match) !== -1) {
            props++;
          }
        });
        if (props >= 1) {
          matches++;
        }
      });
      if (matches === search.length) {
        found.push(i);
      }
      return found;
    }, []);
    return ret;
  };

  const files = data?.data.result?.files ?? [];

  const fileList: SiteFile[] = paginate(
    filter.length > 0 ? fuzzySearch(filter) : (data?.data.result?.files ?? []),
    perPage,
    activePageNumber
  );

  const spacer = (
    <Box display="inline-block" marginLeft={0.5} marginRight={0.5}>
      /
    </Box>
  );

  return (
    <Root {...getRootProps()}>
      {modalType === 'createFolder' && (
        <CreateSiteFolderModal siteId={siteId} onClose={() => setModalType(null)} path={path} />
      )}
      {modalType === 'createFile' && (
        <CreateSiteFileModal siteId={siteId} onClose={() => setModalType(null)} path={path} />
      )}
      <Card>
        <CardHeader
          title={
            <>
              <Typography variant="h2">{t('file_manager')}</Typography>
              <Typography>
                <Trans i18nKey="file_manager_instructions" components={[]} />
              </Typography>
            </>
          }
        />
        <CardContent>
          <Box paddingBottom={3}>
            <Typography variant="h5">
              {splitPath.reduce<ReactNode[]>((prev, curr, i) => {
                if (curr === 'public_html') {
                  return [
                    ...prev,
                    i + 1 === splitPath.length ? (
                      curr
                    ) : (
                      <>
                        <Link onClick={() => navigate(`/sites/${siteId}/file-manager`)}>
                          public_html
                        </Link>
                        {spacer}
                      </>
                    ),
                  ];
                }

                const pathToFile = splitPath.slice(1, i + 1).join('/');

                return [
                  ...prev,
                  i + 1 === splitPath.length ? (
                    curr
                  ) : (
                    <>
                      <Link
                        onClick={() =>
                          navigate(`/sites/${siteId}/file-manager?path=/${pathToFile}`)
                        }
                      >
                        {curr}
                      </Link>
                      {spacer}
                    </>
                  ),
                ];
              }, [])}
            </Typography>
          </Box>

          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Box className={classes.search}>
                <MuiTextField
                  placeholder="Search"
                  value={filter}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFilter(event.target.value)
                  }
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disableTypography component="button">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item justifyContent="flex-end">
              <Box marginRight={2} display="inline-block">
                <input {...getInputProps()} />
                <Button variant="contained" color="primary" onClick={fileOpen}>
                  {t('upload')}
                </Button>
              </Box>

              <Box display="inline-block">
                <Button
                  ref={anchorRef}
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={async () => {
                    setOpenTableMenu(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisV} size="lg" />
                </Button>
                <Popper
                  open={openTableMenu}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 100 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => setOpenTableMenu(false)}>
                          <MenuList
                            autoFocusItem={openTableMenu}
                            id="menu-list-grow"
                            onKeyDown={() => {}}
                          >
                            <MenuItem
                              onClick={() => {
                                setModalType('createFile');
                                setOpenTableMenu(false);
                              }}
                            >
                              {t('create_new_type', { type: t('file') })}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setModalType('createFolder');
                                setOpenTableMenu(false);
                              }}
                            >
                              {t('create_new_type', { type: t('folder') })}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </Grid>
          </Grid>

          <TableContainer>
            <Table aria-label="File Manager Table">
              <TableHead>
                <TableRow>
                  {/* <TableCell width={60}>
                    <Checkbox
                      checked={true}
                      onChange={() => {}}
                      color="primary"
                      inputProps={{
                        disabled: true,
                      }}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Box component="span">
                      {t('name')}
                      <Button onClick={() => setInvertList(!invertList)} color="primary">
                        {invertList ? <ArrowDropUp /> : <ArrowDropDown />}
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>{t('size')}</TableCell>
                  <TableCell>{t('last_modified')}</TableCell>
                  <TableCell>{t('permissions')}</TableCell>
                  <TableCell width={50}>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === 'pending' ? (
                  <RowSkeleton />
                ) : (
                  fileList.map(file => (
                    <FileRow
                      key={file.fullpath}
                      {...file}
                      domain={siteDetails?.domain ?? ''}
                      siteId={siteId}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            totalRowCount={files.length}
            disabled={status === 'pending'}
            perPage={perPage}
            onPageChange={handlePaginationChange}
            onPerPageChange={handlePerPage}
          />
        </CardContent>
      </Card>
    </Root>
  );
};
