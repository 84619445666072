export enum AddThemeActions {
  CHANGE_TABS = 'set tab value',
  SET_PER_PAGE = 'set per page',
  SET_BROWSE = 'set browse',
  SET_INSTALLING_THEME = 'set installing theme',
  SET_PAGE_NUMBER = 'set page number',
}

export interface AddThemeState {
  tab: 'all' | 'blog' | 'fashion' | 'food' | 'real+estate' | 'sports' | 'technology' | 'business';
  page: number;
  perPage: number;
  browse: string;
  installingTheme: string;
}

export const initialState: AddThemeState = {
  tab: 'all',
  page: 1,
  perPage: 12,
  browse: '',
  installingTheme: '',
};

export const reducer = (state: AddThemeState, [type, payload]: [AddThemeActions, any]) => {
  switch (type) {
    case AddThemeActions.CHANGE_TABS: {
      const { tab, browse, installingTheme, page } = payload;
      return {
        ...state,
        tab,
        browse,
        installingTheme,
        page,
      };
    }
    case AddThemeActions.SET_PER_PAGE:
      return {
        ...state,
        perPage: payload,
      };
    case AddThemeActions.SET_PAGE_NUMBER:
      return {
        ...state,
        page: payload,
      };
    case AddThemeActions.SET_BROWSE:
      return {
        ...state,
        browse: payload,
      };
    case AddThemeActions.SET_INSTALLING_THEME:
      return {
        ...state,
        installingTheme: payload,
      };
    default:
      return state;
  }
};
