import { Grid } from '@mui/material';
import { EventSourceZones, SourceZone, useGetEventSources } from 'api/siteMetrics';
import { FC, useEffect } from 'react';
import SourceTable from 'component/partial/Reporting/SourceTable';
import CountryName from 'component/base/CountryName';
import { useTranslation } from 'react-i18next';

interface EventSourcesProps {
  readonly siteId: string;
  readonly duration: string;
}

export type legendTypes = 'topCountries' | 'topIPs' | 'topUriPaths' | 'topWAFRules';

export interface Legend {
  key: legendTypes;
  label: string;
  data: Array<SourceZone>;
}

const EventSources: FC<EventSourcesProps> = ({ siteId, duration }) => {
  const { data, refetch: getEventSources } = useGetEventSources(siteId, duration);
  const eventSources: any = data?.data?.data?.viewer.zones[0] ?? {};
  const { t } = useTranslation();

  const legend: Legend[] = [
    {
      key: 'topCountries',
      label: t('event_source_topCountries_label'),
      data: eventSources ? (eventSources as EventSourceZones).topCountries : [],
    },
    {
      key: 'topIPs',
      label: t('event_source_topIps_label'),
      data: eventSources ? (eventSources as EventSourceZones).topIPs : [],
    },
    {
      key: 'topUriPaths',
      label: t('event_source_topUriPaths_label'),
      data: eventSources ? (eventSources as EventSourceZones).topUriPaths : [],
    },
    {
      key: 'topWAFRules',
      label: t('event_source_topWAFRules_label'),
      data: eventSources ? (eventSources as EventSourceZones).topWAFRules : [],
    },
  ];

  useEffect(() => {
    getEventSources();
  }, [duration]);

  const total = eventSources?.total ?? [{ count: 0 }];
  return (
    <Grid container spacing={3}>
      {legend.map((l: Legend, i: number) => {
        return (
          <SourceTable
            key={`${l.key}-${i}`}
            data={l.data}
            label={l.label}
            id={l.key}
            total={total[0]?.count}
            {...(l.key === 'topCountries' && {
              formatCountry: (key: string) => {
                return <CountryName countryCode={key} />;
              },
            })}
          />
        );
      })}
    </Grid>
  );
};

export default EventSources;
