import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { useFtpAccountsOld, useDeleteFtpAccount, FtpAccount } from 'api/ftpAccounts';
import { AddFtpAccount } from 'component/partial/AddFtpAccount';
import { UpdateFtpAccount } from 'component/partial/UpdateFtpAccount';
import { useParams } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import PaginationControls from 'component/base/PaginationControls';
import { SearchRounded } from '@mui/icons-material';
import { loadDefaultPerPage } from 'utils/paginate';

const PREFIX = 'FtpAccounts';

const classes = {
  name: `${PREFIX}Name`,
};

const Root = styled('div')({
  [`& .${classes.name}`]: {
    maxWidth: '28.125rem',
  },
});

interface Props {
  readonly domain: string;
}
interface PaginationState {
  perPage: number;
  activePageNumber: number;
  filter: string;
}

export function FtpAccounts({ domain }: Props) {
  const { siteId = '' } = useParams<{ siteId: string }>();

  const { mutateAsync } = useDeleteFtpAccount({
    id: siteId,
  });
  const [pagination, setPagination] = useState<PaginationState>({
    perPage: loadDefaultPerPage(),
    activePageNumber: 1,
    filter: '',
  });
  const { isLoading, data, refetch } = useFtpAccountsOld({
    siteId,
    perPage: pagination.perPage,
    activePageNumber: pagination.activePageNumber,
    filter: pagination.filter,
    sortAttr: 'user',
    sortOrder: 'asc',
  });
  const [ftpAccountToDelete, setFtpAccountToDelete] = useState<FtpAccount | null>(null);
  const [ftpAccountToUpdate, setFtpAccountToUpdate] = useState<FtpAccount | null>(null);
  const rows = data?.data.result ?? [];
  const metadata = data?.data.metadata;
  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, [pagination.activePageNumber, pagination.perPage, pagination.filter]);

  let searchTimeout: NodeJS.Timeout | null = null;

  const handlePaginationChange = (activePageNumber: number) => {
    setPagination({ ...pagination, activePageNumber });
  };

  const handlePerPage = (perPage: number) => {
    setPagination({ ...pagination, activePageNumber: 1, perPage });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setPagination({ ...pagination, activePageNumber: 1, filter: event.target.value });
    }, 300);
  };

  const handleSubmit = async () => {
    if (!ftpAccountToDelete || !ftpAccountToDelete.user) return null;

    await mutateAsync({ username: ftpAccountToDelete.user });
    setFtpAccountToDelete(null);
  };

  function renderRows() {
    if (isLoading) {
      return Array.from({ length: 5 }).map((v, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={`${i}-key`}>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Skeleton />
            </TableCell>
            <TableCell>
              <Grid container spacing={2} wrap="nowrap">
                <Grid item>
                  <Skeleton width={36} />
                </Grid>
                <Grid item>
                  <Skeleton width={36} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    }

    if (!rows.length) {
      return (
        <TableRow>
          <TableCell colSpan={4} align="center">
            {t('ftp_accounts_none')}
          </TableCell>
        </TableRow>
      );
    }

    return rows.map(row => {
      return (
        <TableRow key={row.user}>
          <TableCell>
            <Typography noWrap className={classes.name}>
              {row.user}
            </Typography>
          </TableCell>
          <TableCell>{row.reldir}</TableCell>
          <TableCell>{t('mb', { val: Math.round(Number(row.diskused)) })}</TableCell>
          <TableCell>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <IconButton
                  color="primary"
                  onClick={() => setFtpAccountToUpdate(row)}
                  data-testid={`${row.user}-editbutton`}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  onClick={() => setFtpAccountToDelete(row)}
                  data-testid={`${row.user}-deletebutton`}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Root>
      {ftpAccountToDelete ? (
        <ConfirmationDialog
          action="delete"
          title={t('delete_ftp_account')}
          description={t('delete_ftp_account_confirmation_description', {
            user: ftpAccountToDelete.user,
          })}
          onClose={() => setFtpAccountToDelete(null)}
          onConfirm={handleSubmit}
        />
      ) : null}
      {ftpAccountToUpdate ? (
        <UpdateFtpAccount
          domain={domain}
          ftpAccount={ftpAccountToUpdate}
          onClose={() => setFtpAccountToUpdate(null)}
          refetchFtpAccounts={refetch}
        />
      ) : null}
      <Card>
        <CardHeader
          action={<AddFtpAccount domain={domain} refetchFtpAccounts={refetch} />}
          title={t('ftp_accounts')}
        />
        <CardContent>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={handleFilter}
              variant="outlined"
              placeholder={String(t('search_ftp_accounts_label'))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disableTypography component="button">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <TableContainer>
            <Table aria-label="FTP Table" data-testid="ftpAccountsTable">
              <TableHead>
                <TableRow>
                  <TableCell>{t('username')}</TableCell>
                  <TableCell>{t('directory')}</TableCell>
                  <TableCell>{t('usage')}</TableCell>
                  <TableCell width={104}>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderRows()}</TableBody>
            </Table>
          </TableContainer>
          <PaginationControls
            totalRowCount={metadata?.total ?? rows.length}
            perPage={pagination.perPage}
            onPageChange={handlePaginationChange}
            onPerPageChange={handlePerPage}
          />
        </CardContent>
      </Card>
    </Root>
  );
}
