import { FC } from 'react';
import { Grid, TableRow, TableCell, IconButton } from '@mui/material';
import { RemoveRedEye, Done, Delete, Close } from '@mui/icons-material';

interface SSHRowProps {
  readonly name: string;
  readonly isAuthorized: boolean;
  readonly onPreview: () => void;
  readonly onActivate: () => void;
  readonly onDeactivate: () => void;
  readonly onDelete: () => void;
}

const SSHRow: FC<SSHRowProps> = ({
  name,
  isAuthorized,
  onPreview,
  onActivate,
  onDeactivate,
  onDelete,
}) => {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{isAuthorized ? 'Authorized' : 'Not Authorized'}</TableCell>
      <TableCell>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <IconButton onClick={onPreview} color="primary" size="large">
              <RemoveRedEye />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={isAuthorized ? onDeactivate : onActivate}
              color="primary"
              size="large"
            >
              {isAuthorized ? <Close /> : <Done />}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={onDelete} color="primary" size="large">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default SSHRow;
