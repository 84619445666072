import { TableRow, TableCell, IconButton, Typography, Tooltip, Grid } from '@mui/material';
import {
  DoneRounded,
  VerticalAlignTopRounded,
  DeleteRounded,
  CloseRounded,
} from '@mui/icons-material';
import { FC } from 'react';
import { ActivatePayload, DeletePayload, Plugin, UpgradePayload } from 'api/plugins';
import { useTranslation } from 'react-i18next';

interface PluginRowProps {
  readonly showModal: (data: ActivatePayload | DeletePayload | UpgradePayload) => void;
  readonly data: Plugin;
}

const PluginRow: FC<PluginRowProps> = ({ data, showModal }) => {
  const { version, status, title, name, update } = data;
  const { t } = useTranslation();

  const updateTooltip = t(update === 'none' ? 'update_not_available' : 'update');
  const statusTooltip = t(
    status === 'active' ? 'tooltip_toggle_deactivate' : 'tooltip_toggle_activate'
  );
  const deleteTooltip = t('tooltip_toggle_delete');

  return (
    <TableRow>
      <TableCell>{title === '' ? name : title}</TableCell>
      <TableCell>{version}</TableCell>
      <TableCell>
        <Typography color={status === 'inactive' ? 'textSecondary' : 'inherit'}>
          {status}
        </Typography>
      </TableCell>
      <TableCell>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <Tooltip arrow placement="top" title={statusTooltip}>
              <IconButton
                onClick={() => {
                  if (status === 'must-use') return null;

                  showModal({
                    plugin: name,
                    status: status === 'active' ? 'deactivate' : 'activate',
                    title,
                  });
                }}
                color="primary"
                size="large"
                data-testid="togglePluginActivationButton"
              >
                {status === 'inactive' ? <DoneRounded /> : <CloseRounded />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow placement="top" title={updateTooltip}>
              <IconButton
                color="primary"
                onClick={() => {
                  if (update === 'none') {
                    return null;
                  }

                  showModal({
                    plugin: name,
                    update: true,
                    title,
                  });
                }}
                size="large"
                data-testid="pluginUpdateButton"
              >
                <VerticalAlignTopRounded {...(update === 'none' && { color: 'disabled' })} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow placement="top" title={deleteTooltip}>
              <IconButton
                onClick={() =>
                  showModal({
                    plugins: name,
                    title,
                  })
                }
                color="primary"
                size="large"
                data-testid="pluginDeleteButton"
              >
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default PluginRow;
