import { SiteBandwidthTopUsageMetric, SiteBandwidthTopUsage } from 'api/siteMetrics';
import { Grid, Box } from '@mui/material';
import SourceTable from 'component/partial/Reporting/SourceTable';
import { useTranslation } from 'react-i18next';
import { ResponsiveChoropleth } from '@nivo/geo';
import countryCodesMap from './countryCodesMap';
import countriesFile from './world_countries.json';
import CountryName from '../../../base/CountryName';
import { AxiosResponse } from 'axios';
import { formatNumberReporting } from 'utils/number';

type Props = {
  readonly data?: AxiosResponse<SiteBandwidthTopUsage, any>;
};

export default function TopUsage({ data }: Props) {
  const zoneData = data?.data?.data?.viewer.zones[0];
  const { t } = useTranslation();

  if (!zoneData) {
    return null;
  }

  const legend = Object.keys(zoneData).reduce<
    Array<{
      key: string;
      label: string;
      data: SiteBandwidthTopUsageMetric[];
    }>
  >((prev, key) => {
    const typedKey = key as keyof typeof zoneData;

    if (typedKey === 'total' || typedKey === '__typename' || typedKey === 'countries') {
      return prev;
    }
    const metric = zoneData[typedKey];

    return [
      ...prev,
      {
        key,
        label: t(`overview_reporting.${String(key)}`),
        data: metric.sort((a, b) => b.count - a.count),
      },
    ];
  }, []);

  const total = zoneData?.total ?? [{ count: 0 }];
  const countries = zoneData?.countries.map(datum => ({
    id: countryCodesMap[datum.dimensions.metric] ?? '',
    value: datum.count,
  }));

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={7} sm={12}>
          <Box height="400px">
            <ResponsiveChoropleth
              features={countriesFile.features}
              data={countries}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              colors="blues"
              domain={[0, countries.reduce<number>((prev, curr) => prev + curr.value, 0)]}
              unknownColor="#d8d8d8"
              label="properties.name"
              valueFormat={value => {
                return formatNumberReporting(value, 2);
              }}
              projectionTranslation={[0.5, 0.5]}
              projectionRotation={[0, 0, 0]}
              borderWidth={0.5}
              borderColor="#ffffff"
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  justify: true,
                  translateX: 20,
                  translateY: -100,
                  itemsSpacing: 0,
                  itemWidth: 94,
                  itemHeight: 18,
                  itemDirection: 'left-to-right',
                  itemTextColor: '#444444',
                  itemOpacity: 0.85,
                  symbolSize: 18,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000000',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item md={5} sm={12}>
          <SourceTable
            data={zoneData?.countries.sort((a, b) => b.count - a.count)}
            label={t('overview_reporting.countries')}
            id={'countries-table'}
            total={total[0]?.count}
            formatCountry={(key: string) => {
              return <CountryName countryCode={key} />;
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {legend.map((l, i) => {
          return (
            <SourceTable
              key={`${l.key}-${i}`}
              data={l.data}
              label={l.label}
              id={l.key}
              total={total[0]?.count}
            />
          );
        })}
      </Grid>
    </>
  );
}
