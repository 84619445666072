import React from 'react';
import { Card, CardHeader, CardContent, MenuItem, Tabs, Tab, TextField } from '@mui/material';
import { CDNReporting } from './CDNReporting';
import WAFReport from 'component/partial/Reporting/WAFReport/WAFReport';
import Overview from 'component/partial/Reporting/Overview';
import { useParams } from 'react-router-dom';
import WafActivityLog from 'component/partial/Reporting/WAFReport/ActivityLog';
import { useTranslation } from 'react-i18next';

export function Reporting() {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const [reportShown, setReportShown] = React.useState<'overview' | 'cdn' | 'waf'>('overview');
  const [timeframe, setTimeframe] = React.useState<string>('24h');
  const timeframes = [
    {
      label: t('last_30_minutes'),
      value: '30m',
    },
    {
      label: t('last_6_hours'),
      value: '6h',
    },
    {
      label: t('last_12_hours'),
      value: '12h',
    },
    {
      label: t('last_24_hours'),
      value: '24h',
    },
    {
      label: t('last_72_hours'),
      value: '72h',
    },
    {
      label: t('last_7_days'),
      value: '7d',
    },
    {
      label: t('last_30_days'),
      value: '30d',
    },
  ];
  const duration = reportShown === 'waf' ? timeframes.slice(0, 5) : timeframes;

  return (
    <>
      <Card>
        <CardHeader
          action={
            <TextField
              id="select-timeframe"
              select
              value={timeframe}
              variant="outlined"
              onChange={e => setTimeframe(e.target.value)}
            >
              {duration.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          }
          title={
            <Tabs
              aria-label="tabs"
              variant="scrollable"
              onChange={(_event: React.ChangeEvent<any>, tabValue: 'cdn' | 'waf') => {
                setReportShown(tabValue);
                if (tabValue === 'waf' && (timeframe === '7d' || timeframe === '30d')) {
                  setTimeframe('72h');
                }
              }}
              value={reportShown}
            >
              <Tab label={t('overview')} value="overview" />
              <Tab label={t('cdn')} value="cdn" />
              <Tab label={t('waf')} value="waf" />
            </Tabs>
          }
        />
        <CardContent>
          {reportShown === 'overview' && <Overview siteId={siteId} duration={timeframe} />}
          {reportShown === 'cdn' && <CDNReporting siteId={siteId} duration={timeframe} />}
          {reportShown === 'waf' && <WAFReport siteId={siteId} duration={timeframe} />}
        </CardContent>
      </Card>
      {reportShown === 'waf' && <WafActivityLog siteId={siteId} duration={timeframe} />}
    </>
  );
}
